
import {TEST_RESULT_TYPE} from "__config/dashboardConfig";
import SingleChip from "_components_public/SingleChip";
import { FaceIcon, TestResultIconForChip } from '__icons/IconProvider';

export default function ChipOfTestResult ({idx,TEST_RESULT_TYPE2})  {
    let TEST_RESULT_TYPEset = TEST_RESULT_TYPE2;
    let chip_prop = TEST_RESULT_TYPE[idx] ;
    if(TEST_RESULT_TYPEset){
        chip_prop = TEST_RESULT_TYPEset[idx] ;
    }
    //console.log('chip_prop',chip_prop);
    const IconByTestResult = TestResultIconForChip(idx) ;

    const icon = IconByTestResult ? <IconByTestResult style={{color:'#fff', width:'18px', height:'18x'}}/> : undefined;

    return <SingleChip label={chip_prop.label } color={chip_prop.color} icon={icon}  />
  };