
import { TEST_RESULT_TYPE } from '__config/dashboardConfig';
import { FaceIcon } from "__icons/IconProvider";

import { useMemo } from 'react';
import { useContext, DistributorContext } from '__context_api/DistributorContext';


import { listOfTestResult, totalTestCountFromObject } from "__lib/cTools";

import CardBasic  from  '_components_public/CardBasic';
import DounutChart_2  from '_components_chart/DounutChart_2';
import ChipsOfAllTestResult from "_components_public/ChipsOfAllTestResult";
import BarTiny from "_components_chart/BarTiny";

import { debugMsg } from "__lib/debugMsg";
const IS_LOCAL_DEBUG = false;
const DebugMsg = debugMsg(IS_LOCAL_DEBUG);


const overallCountByTestResult = (result) => {
    const _gross = listOfTestResult(result);
    const tatal_test_cnt = totalTestCountFromObject(result,"cardmap");
    console.log('tatal_test_cnt in cardmap',tatal_test_cnt);
    console.log('_gross',_gross);
    return _gross.map(row => (
        {
            idx : row.idx,
            label : TEST_RESULT_TYPE[row.idx].label,
            sub_total : row.cnt,
            content :  `${(row.cnt/tatal_test_cnt*100).toFixed(1)}% (${row.cnt})`,
            color :  TEST_RESULT_TYPE[row.idx].color,
            icon : <FaceIcon style={{color:'#666'}}/>,
        }));
};

const pieByTestResult = (result) => {
    const _gross = listOfTestResult(result);
    return _gross.map(row => (
        {
            idx : row.idx,
            label : TEST_RESULT_TYPE[row.idx].label,
            value : row.cnt,
            color :  TEST_RESULT_TYPE[row.idx].color,
        }));
};

const listByVendor = (by_vendor) => {
    if (!by_vendor || by_vendor.length===0 || Object.keys(by_vendor).length===0) {
        return [];
    };
    return by_vendor.map( (vv) => (
        {
            idx : vv.idx,
            label: vv.name.slice(0,4), 
            cnt :  vv.count,
            name : vv.name,        
        }
        ));
};

export default function CardMap01_MapSide({clickedItem, _params,textLangSiteAnalysissearch}) {

    const { login_info, }  = useContext(DistributorContext);

    const token = login_info.token;   
    const params = (
        (!clickedItem || Object.keys(clickedItem).length===0)
        ? undefined
        : {
            "distributor_code": login_info.distributor_code,
            'grid_center_longitude' : clickedItem.lng,
            'grid_center_latitude' : clickedItem.lat,
            "zoom_level" : clickedItem.current_zoom,
            "start_time": _params.start_time, //"2021/01/01",
            "end_time": _params.end_time, //"2022/12/31",
            "vendor_code_name" : _params.vendor_code_name == undefined ? undefined :  _params.vendor_code_name,
            "periodical_type": "weekly",
            "max_count": clickedItem.max_count,
            "negative" : clickedItem.negative,
            "positive" : clickedItem.positive,
            "inconclusive" : clickedItem.inconclusive,
            "invalid" :clickedItem.invalid,
            "cancel": clickedItem.cancel,
            "vendorinfo":clickedItem.vendorinfo
        }
    );

    // let vendor = {
    //     V_CBNU: {name: 'Chungbuk National University', color: 'rgb(116,184,22,0.2)', use_count: clickedItem.V_CBNU},
    //     V_CJH: {name: 'Cheongju Hospital', color: 'rgb(214,57,57,0.2)', use_count: clickedItem.V_CJH},
    //     V_GNH: {name: 'Ghana Hospital', color: 'rgb(32,107,196,0.2)', use_count: clickedItem.V_GNH},
    //     V_OSH: {name: 'Onsarang Hospital', color: 'rgb(245,159,0,0.2)', use_count: clickedItem.V_OSH},
    //     V_SNH: {name: 'Sangnok Hospital', color: 'rgb(121,65,229,0.2)', use_count: clickedItem.V_SNH}
    // };

    let vendor = clickedItem.vendorinfo;

    const test_result =  {negative: clickedItem.negative, positive: clickedItem.positive, indeterminate: clickedItem.inconclusive, invalid: clickedItem.invalid, cancel: clickedItem.cancel} || {};
    const overall_count_by_test_result = overallCountByTestResult(test_result);
    const pie_by_test_result = useMemo( ()=> pieByTestResult(test_result), [test_result]);
    const total_test_count = totalTestCountFromObject(test_result);
    const use_by_vendor = useMemo( ()=> listByVendor( vendor || {} ), [vendor]);
    console.log('overall_count_by_test_result',overall_count_by_test_result);
    const CardBody2 =  (
        <div style={{height:'530px'}}>   
            <div style={{marginLeft:'5px'}}>
                <DounutChart_2 data ={pie_by_test_result} total_test_count={total_test_count} textLangAnalysisCard={textLangSiteAnalysissearch}/>
            </div>
            <div align='center' style={{padding :'15px 0 0 25px'}}>
                <ChipsOfAllTestResult rows={overall_count_by_test_result} width='200px' />
            </div>
            <div align='center' style={{padding :'55px 0 0 0'}}>

                {(clickedItem.vendorinfo == undefined ? textLangSiteAnalysissearch.error1 :
                <BarTiny data={use_by_vendor}  width={230} height={180}  />

                )}
            </div>
        </div>
    );

    //search_option

    return (
        <div  style={{maxWidth:240, height:660}}>            
            <div style={{maxWidth:'220px', padding:"0 0 0px 0"}} >
                <DebugMsg data = {clickedItem} label='CardMap01_MapSide:clickedItem=' />
                <DebugMsg data = {params} label='CardMap01_MapSide:params=' />
                {/* <DebugMsg data = {location_map_analysis_detail} label='CardMap01_MapSide:clickedItem=' /> */}
            </div>
            {
                (!clickedItem.vendorinfo ==undefined || Object.keys(clickedItem).length===0)
                ? ''
                :(
                    <CardBasic
                    card_title={textLangSiteAnalysissearch.title}
                    card_body = {CardBody2}
                    style={{width:255, height:660}}/>
                )
            }
        </div>
    )
}