
import moment from 'moment';

import * as React from 'react';
import TextField from '@mui/material/TextField';


import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';


export default function BasicDatePicker({label='날짜', passValue}) {
    const yearDateDay = new Date ();

    const todayString = yearDateDay.getFullYear().toString()+"/" + (yearDateDay.getMonth()+1).toString()+ "/"  + yearDateDay.getDate().toString();
    const [value, setValue] = React.useState((label =='시작 날짜' || label =='Start Date') ? "2020/01/01" : moment(todayString).format("YYYY/MM/DD"));
  const handleSelect = (vv) => {
    setValue(vv);
    passValue(moment(vv).format("YYYY/MM/DD"));
  };


  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        inputFormat={"yyyy/MM/dd"}
        mask={"____/__/__"}
        value={value}
        onChange={(newValue) => {
          handleSelect(newValue == null ? todayString : newValue);
        }}
        renderInput={(params) => <TextField {...params} size='small' style={{backgroundColor:'#fff'}} />}
        
      />
    </LocalizationProvider>
  );
}