
import React, {useState} from 'react';

import { Typography, Checkbox, Button} from '@mui/material';


import TableWithSortAndActionOnedrop from '_components_public/TableWithSortAndActionOnedrop';
import {TableRowDense2, TableCellDense2} from "_components_table/TableElement";

import ChipOfTestResult from "_components_public/ChipOfTestResult";
import {requestSendSmsFuc, sendEmailFun} from "../__api/requestDeleteTableRow";

let test_result_mapFiltered = null;
export default function TableEmployeeOnedrop( {employee_data, setfilterPositionFun, setfilterGenderFun, setfilterRecentTestResultFun, position_to_select, filterPosition, filterGender, filterRecentTestResult, settest_result_by_employee, textLangSitetable, TEST_RESULT_TYPE, textLangtablefilterbox,textLangtable,props} ) {
  const [buttonDivTF,setbuttonDivTF] = useState(false);

/*
TEST_RESULT_TYPE={TEST_RESULT_TYPE}
const sendSmsToRowOfPhoneNumber =(row) =>{
  client.messages
      .create({
        body: 'sms test 입니다.',
        from: '+12548242435',
        to: '+8201097851715'
      })
      .then(message => console.log(message.sid));
}
*/

//console.log('textLangtablefilterbox',textLangtablefilterbox);
//console.log('textLangSitetable33',textLangSitetable);


  const rows = employee_data.map( (row, index) => {
    return {
      idx : index,
      nid : index,
      employee_code : row.employee_code,
      name : row.name,
      department : row.department,
      position : row.position,
      recent_test_result : row.recent_test_result,
      gender : row.gender.slice(0,8),
      contact : row.contact,
      phone:row.phone,
      email:row.email
    }
  });

  const headerCells = [
    {
      idx: 'employee_code',
      numeric: false,
      disablePadding: true,
      label: textLangSitetable.colum1,
    },
    {
      idx: 'name',
      numeric: false,
      disablePadding: true,
      label: textLangSitetable.colum2,
    },
    {
      idx: 'department',
      numeric: false,
      disablePadding: true,
      label: textLangSitetable.colum3,
    },
    {
      idx: 'position',
      numeric: false,
      disablePadding:  true,
      label: textLangSitetable.colum4,
    },
    {
      idx: 'recent_test_result',
      numeric: false,
      disablePadding:  true,
      label: textLangSitetable.colum5,
    },
    {
      idx: 'gender',
      numeric: false,
      disablePadding:  true,
      label: textLangSitetable.colum6,
    },
    {
      idx: 'contact',
      numeric: false,
      disablePadding:  true,
      label: textLangSitetable.colum7,
    },
  ];


  const tableCellsOfRow = (row, labelId, isItemSelected) => (
    <>
      <TableCellDense2 padding="checkbox" >
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            'aria-labelledby': labelId,
          }}
        />
      </TableCellDense2>
      <TableCellDense2           
            id={labelId}
            scope="row"
            padding="none"
            style={{width:80}}
          >     
          {row.employee_code}
      </TableCellDense2>
      <TableCellDense2 align="left" style={{width:100}}><Typography variant='h7' sx={{ fontWeight: 'bold' }}>{row.name}</Typography></TableCellDense2>
      <TableCellDense2 align="left" style={{width:140}}>{row.department}</TableCellDense2>
      <TableCellDense2 align="left" style={{width:80}}>{row.position}</TableCellDense2>
      <TableCellDense2 align="left" style={{width:70}}><ChipOfTestResult idx={row.recent_test_result} TEST_RESULT_TYPE2={TEST_RESULT_TYPE}/></TableCellDense2>
      <TableCellDense2 align="left" style={{width:60}}>{row.gender}</TableCellDense2>
      <TableCellDense2 align="left" style={{width:60}}>
        {(!buttonDivTF) ? (<Button size='small' variant='contained' style={{textTransform: 'none'}} onClick={() => setbuttonDivTF(!buttonDivTF)}>{row.contact}</Button>)  :
            (<div><img src={require('__images/Smsdash.png') } style={{width:"40px",height:"30px"}} onClick={() => {setbuttonDivTF(!buttonDivTF);requestSendSmsFuc(row, textLangSitetable.title);}}/>
              <img src={require('__images/email.png') } style={{width:"40px",height:"30px"}} onClick={() => {setbuttonDivTF(!buttonDivTF); sendEmailFun(row,textLangSitetable.title);}}/></div>)

        }

      </TableCellDense2>
    </>
  );

  return (
    <>
        <TableWithSortAndActionOnedrop table_title={textLangSitetable.title} rows={[...rows]} filterPosition={filterPosition} filterRecentTestResult={filterRecentTestResult} filterGender={filterGender} setfilterPositionFun={setfilterPositionFun} setfilterGenderFun={setfilterGenderFun} setfilterRecentTestResultFun={setfilterRecentTestResultFun}
                                filtedrows={test_result_mapFiltered} headerCells={headerCells} tableCellsOfRow={tableCellsOfRow} position_to_select={position_to_select} settest_result_by_employee={settest_result_by_employee} textLangtablefilterbox={textLangtablefilterbox} textLangSitetable={textLangSitetable} textLangtable={textLangtable}/>
    </>
  )
};
