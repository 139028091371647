
//import {FaceIcon, BarChartIcon, MapIcon, PersonOutlineIcon, } from "__icons/IconProvider";


import {useState} from 'react';

import { useContent, DistributorContext, DistributorContextProvider } from "__context_api/DistributorContext";

import { 
    useLocationAnalysis, 
    useLocationMapAnalysisDetail, 
    useLocationMapAnalysis, 
    useLocationMapDot,
    useLocationVender
    } from "__api/requestOneDropDistributorData";

//import {Button, Stack, Typography} from '@mui/material';

import Header from "./Header";

import MenuSelect  from 'main/SideNavigation'
import {langTextForDashboard} from "../__lang/lang";
// import Distributor01Analysis from "../containers/Distributor01Analysis";
import {BarChartIcon, MapIcon, PersonOutlineIcon} from "../__icons/IconProvider";
// import Distributor03RegionMap from "../containers/Distributor03RegionMap";
// import Distributor02Seller from "../containers/Distributor02Seller";
import OneDropDis01Analysis from "../containers/oneDropDis01Analysis";
import OneDropDis03RegionMap from "../containers/oneDropDis03RegionMap";
import OneDropDis02Seller from "../containers/oneDropDis02Seller";
// import EndUser01Analysis from "../containers/EndUser01Analysis";
// import EndUser03TestCenter from "../containers/EndUser03TestCenter";
// import EndUser02Employee from "../containers/EndUser02Employee";
import Onedrop01Analysis from "../containers/oneDrop01Analysis";
import Onedrop03TestCenter from "../containers/oneDrop03TestCenter";
import Onedrop02Employee from "../containers/oneDrop02Employee";
import ComponentArticleMain from "../containers/onedropCSArticleBoardMain";
import {TestResultTypeChangeDistributor} from "../__config/dashboardConfig";
//import UntilIsLoading from "_components_public/UntilIsLoading";

/*
import WebFont from 'webfontloader';
WebFont.load({
  google: {
    families: ['Noto Sans KR:300,400,500', 'sans-serif']
  }
});
*/






let langdis ="ko-KR";
let MENU_ITEMS = {
    // 'Dis01Analysis' : {
    //     jsx : <Distributor01Analysis/>,
    //     label : '분석',
    //     icon : BarChartIcon ,
    // },
    // 'Dis03RegionMap' : {
    //     jsx : <Distributor03RegionMap/>,
    //     label : '지역 맵',
    //     icon :  MapIcon ,
    // },
    // 'Dis02Seller' : {
    //     jsx : <Distributor02Seller/>,
    //     label : '판매처',
    //     icon :  PersonOutlineIcon ,
    // },
    'OneDropDis01Analysis' : {
        jsx : <OneDropDis01Analysis defultlang={langdis} />,
        label : '분석',
        icon : BarChartIcon ,
    },
    'OneDropDis03RegionMap' : {
        jsx : <OneDropDis03RegionMap defultlang={langdis}/>,
        label : '지역 맵',
        icon :  MapIcon ,
    },
    'OneDropDis02Seller' : {
        jsx : <OneDropDis02Seller defultlang={langdis}/>,
        label : '판매처',
        icon :  PersonOutlineIcon ,
    },
    // 'User01Analysis' : {
    //     jsx : <EndUser01Analysis/>,
    //     label : '분석',
    //     icon : BarChartIcon ,
    // },
    // 'User03TestCenter' : {
    //     jsx : <EndUser03TestCenter/>,
    //     label : '테스트센터',
    //     icon : MapIcon ,
    // },
    // 'User02Employee' : {
    //     jsx : <EndUser02Employee/>,
    //     label : '멤버',
    //     icon : PersonOutlineIcon ,
    // },
    'Onedrop01Analysis' : {
        jsx : <Onedrop01Analysis defultlang={langdis}/>,
        label : '분석',
        icon : BarChartIcon ,
    },
    'Onedrop03TestCenter' : {
        jsx : <Onedrop03TestCenter defultlang={langdis}/>,
        label : '테스트센터',
        icon : MapIcon ,
    },
    'Onedrop02Employee' : {
        jsx : <Onedrop02Employee defultlang={langdis}/>,
        label : '멤버',
        icon : PersonOutlineIcon ,
    },
    'ComponentArticleMain' : {
        jsx : <ComponentArticleMain/>,
        label : 'CS게시판',
        icon : PersonOutlineIcon ,
    }
};




















const dateToSelect = (periodical) => {
    if (!periodical || Object.keys(periodical).length===0) {
        return [];        
    };
    return Object.keys(periodical).sort();   
};

const vendorToSelect = (vendor) => {    // from obj
    if (!vendor || Object.keys(vendor).length===0) {
        return [];        
    };
    return Object.entries(vendor).map( ([code,row]) => (row.name));
};

const vendorToSelect2 = (vendor) => {    // from obj
    if (!vendor || Object.keys(vendor).length===0) {
        return [];        
    };
    return Object.entries(vendor).map( ([code,row]) => ({code:row.code, label:row.name}));
};

const vendors = (location_vendor) => {   // 
    if (!location_vendor || !location_vendor.vendor || !Array.isArray(location_vendor) ) {
        return {};        
    };
    return Object.fromEntries( 
        location_vendor.vendor.map(
            row => [ 
                row.code, 
                { idx:row.code, code:row.code, name:row.name, color: row.color}
            ]
        ));         
};


//let lang ="en-US";
let lang ="en-US";

export default function OnedropDisUser ({user}) {

    const MENU_LIST_BY_USER_TYPE = ['OneDropDis01Analysis', 'OneDropDis03RegionMap','OneDropDis02Seller']
    const [menuSelected, setMenuSelected] = useState(MENU_LIST_BY_USER_TYPE[0]);  

    const login_info_from_local = JSON.parse(window.localStorage.getItem("onedrop") || '{}');
    const login_info = {
        token : 'access_token=b718f418d1ac4a49aa9b7201a35ed224',
        distributor_code: login_info_from_local.distributor_code
    };
    let params = {};
    const [defultlang,setdefultlang] = useState(lang);
    //console.log('login_info_from_local.distributor_code',login_info_from_local.distributor_code);
    //console.log('defultlang in OnedropDisUser',user);

    if(login_info_from_local.distributor_code){
        if(login_info_from_local.distributor_code == 'onedrop'){
            params ={
               // "distributor_code": login_info_from_local.distributor_code,
                "distributor_code": login_info_from_local.distributor_code ,
                "grid_center_longitude" : 37,
                "grid_center_latitude" : 127,
                "zoom_level" : 9,
                "start_time": "2020/01/01",
                "end_time": "2023/12/31",
                "periodical_type": "weekly",
                "page" : 1,
                "entries" : 100,
                "user_id_contains":'1drop_',

            }
        }else if(login_info.distributor_code == 'mediconhellas'){
                params = {
                    "distributor_code": login_info.distributor_code ,
                    "grid_center_longitude" : 37,
                    "grid_center_latitude" : 127,
                    "zoom_level" : 9,
                    "start_time": "2020/01/01",
                    "end_time": "2023/12/31",
                    "periodical_type": "weekly",
                    "page" : 1,
                    "entries" : 1000,
                    "user_id_contains": 'Hellas_'
                }
        }else if(login_info.distributor_code == 'biowings'){
                params = {
                    "distributor_code": login_info.distributor_code ,
                    "grid_center_longitude" : 37,
                    "grid_center_latitude" : 127,
                    "zoom_level" : 9,
                    "start_time": "2020/01/01",
                    "end_time": "2023/12/31",
                    "periodical_type": "weekly",
                    "page" : 1,
                    "entries" : 1000,
                    "user_id_contains": 'Biowings_'
                }
        }
        }else{
                params = {
                    "distributor_code": undefined ,
                    "grid_center_longitude" : 37,
                    "grid_center_latitude" : 127,
                    "zoom_level" : 9,
                    "start_time": "2020/01/01",
                    "end_time": "2023/12/31",
                    "periodical_type": "weekly",
                    "page" : 1,
                    "entries" : 1000
                }
        }



    const [is_loading1, location_analysis] = useLocationAnalysis(params);
    const [is_loading2, location_map_analysis_detail] = useLocationMapAnalysisDetail(params);
    const [is_loading_map_analysis, location_map_analysis] = useLocationMapAnalysis(params);
    const [is_loading4, location_vendor] = useLocationVender(params);
    const [is_loading_map_dot, location_map_dot] = useLocationMapDot(params);

    //let lang ="ko-KR";

    //TestResultTypeChangeDistributor();
    const langTextTotal = langTextForDashboard;
    const langTextKr = langTextForDashboard["ko-KR"];
    const langTextEn = langTextForDashboard["en-US"];
    //console.log('langTextKr',langTextKr);
    //console.log('langTextEn',langTextEn);
    let textlang = langTextTotal[defultlang];

    let sideNavi = textlang.distributor.sidenavi;

    let testresulttype = textlang.testresulttype;
    //console.log('testresulttype',testresulttype);
    let TEST_RESULT_TYPE ={
        'negative'  : {idx : 'negative' , label : testresulttype.label1, color : '#76F082',},//0
        'positive' : {idx : 'positive' , label : testresulttype.label2,  color : '#F16363',},//1
        'inconclusive' : {idx : 'inconclusive' ,label : testresulttype.label3,  color : '#FFD600',},//2
        'invalid' : {idx : 'invalid' ,label : testresulttype.label4,color : '#C1C1C1',},//3
        'cancel' : {idx : 'cancel' ,label : testresulttype.label5, color : '#EBEFF2',},//4
        //'incomplete' : {idx : 'incomplete' ,label : '미완료', color : '#EBEFF2',} //5
    }




    MENU_ITEMS = {
        // 'Dis01Analysis': {
        //     jsx: <Distributor01Analysis/>,
        //     label: sideNavi.menu1,
        //     icon: BarChartIcon,
        // },
        // 'Dis03RegionMap': {
        //     jsx: <Distributor03RegionMap/>,
        //     label: sideNavi.menu2,
        //     icon: MapIcon,
        // },
        // 'Dis02Seller': {
        //     jsx: <Distributor02Seller/>,
        //     label: sideNavi.menu3,
        //     icon: PersonOutlineIcon,
        // },
        'OneDropDis01Analysis': {
            jsx: <OneDropDis01Analysis defultlang={defultlang} TEST_RESULT_TYPE={TEST_RESULT_TYPE}/>,
            label: sideNavi.menu1,
            icon: BarChartIcon,
        },
        'OneDropDis03RegionMap': {
            jsx: <OneDropDis03RegionMap defultlang={defultlang} TEST_RESULT_TYPE={TEST_RESULT_TYPE}/>,
            label: sideNavi.menu2,
            icon: MapIcon,
        },
        'OneDropDis02Seller': {
            jsx: <OneDropDis02Seller defultlang={defultlang}/>,
            label: sideNavi.menu3,
            icon: PersonOutlineIcon,
        },
        // 'User01Analysis': {
        //     jsx: <EndUser01Analysis/>,
        //     label: sideNavi.menu1,
        //     icon: BarChartIcon,
        // },
        // 'User03TestCenter': {
        //     jsx: <EndUser03TestCenter/>,
        //     label: sideNavi.menu2,
        //     icon: MapIcon,
        // },
        // 'User02Employee': {
        //     jsx: <EndUser02Employee/>,
        //     label: sideNavi.menu3,
        //     icon: PersonOutlineIcon,
        // },
        'Onedrop01Analysis': {
            jsx: <Onedrop01Analysis defultlang={defultlang}/>,
            label: sideNavi.menu1,
            icon: BarChartIcon,
        },
        'Onedrop03TestCenter': {
            jsx: <Onedrop03TestCenter defultlang={defultlang}/>,
            label: sideNavi.menu2,
            icon: MapIcon,
        },
        'Onedrop02Employee': {
            jsx: <Onedrop02Employee defultlang={defultlang}/>,
            label: sideNavi.menu3,
            icon: PersonOutlineIcon,
        },
        'ComponentArticleMain': {
            jsx: <ComponentArticleMain defultlang={defultlang}/>,
            label: 'CS게시판',
            icon: PersonOutlineIcon,

        }
    }

    return (
        
        <DistributorContextProvider 
                value = {{
                    login_info : login_info_from_local, //login_info,
                    location_analysis : location_analysis,
                    date_to_select : dateToSelect(location_analysis.periodical),
                    vendor_to_select : vendorToSelect(location_vendor.vendor),
                    vendor_to_select2 : vendorToSelect2(location_vendor.vendor),

                    location_vendor : location_vendor,
                    vendors : vendors(location_vendor),

                    location_map_analysis_detail : location_map_analysis_detail,
                    location_map_analysis : location_map_analysis,
                    location_map_dot : location_map_dot,
                    is_loading_map_dot : is_loading_map_dot,
                    is_loading_map_analysis : is_loading_map_analysis,

                    lang:defultlang,
                    textOflang:langTextTotal,

                    }}
                 >
            
            <div style={{ height:'auto', paddingBottom:'20px'}}>

                <div style={{
                        float:'left', 
                        width:170, height:'auto', 
                        marginTop:0, 
                        border: 'solid 0px blue'}}> 
                    <MenuSelect
                        menu_list_by_user_type={MENU_LIST_BY_USER_TYPE} 
                        menuSelected={menuSelected} 
                        setMenuSelected={setMenuSelected}
                        login_info = {login_info_from_local}
                        lang={defultlang}
                        textOflang={langTextTotal}
                        mode={"distributor"}
                        />                                                                                            
                </div>

                <div style={{marginLeft:172, width:1040, height:'auto', border: 'solid 0px grey'}}>                 
                    <Header label={MENU_ITEMS[menuSelected].label} defultlang={defultlang} setdefultlang={setdefultlang}/>
                    <div  defultlang={defultlang}>
                        {MENU_ITEMS[menuSelected].jsx}
                    </div>                   
                </div>

            </div>
        </DistributorContextProvider>
        
    )    
};



export {lang as langDisUser};
