import {useContext, useEffect, useLayoutEffect, useState} from 'react';
import {Box, TextField, TextFieldwithBG, Button, Grid, FormControl, InputLabel, Select, Menu, MenuItem } from "__ui_components/UIComponents";

import SelectItem from "_components_public/SelectItem";
import {useGetBoardList, useFieldTestCenter} from "../__api/requestOneDropData";
import axios from "axios";
import {SiteContext} from "../__context_api/SiteContext";
import {ArticleContext} from "./onedropCSArticleBoardMain";

export default function ComponentArticleWrite() {
    const { articletypeSelected, setarticletypeSelected } = useContext(ArticleContext);
    const { articleSeqSelected, setarticleSeqSelected } = useContext(ArticleContext);
    //let BoardList = null;
    //const [is_loading, BoardList] = useGetBoardModify();
    //const [BoardList,setBoardList] = useState({});
   // let BoardList = null;
    const { login_info, setlogin_info} = useContext(SiteContext);
    //BoardList = await getBoardList();

    //useEffect(BoardList,[]);
    function isEmptyObj(obj)  {
        if(typeof obj === Object){
            if(obj.constructor === Object
                && Object.keys(obj).length === 0)  {
                return true;
            }
        }
        return false;
    }



    // useEffect(() => {
    //     if(isEmptyObj(BoardList) != true){
    //
    //     }; return () => null;},[ BoardList]);
    //

        return (

            <>

                    <table className="tg">
                        <thead>
                        <tr>
                            <th className="tg-0pky" >제목</th>
                            <th className="tg-0pky" colSpan="5" ><input type={'text'} id={'titleinput'} name={'titleinput'}/></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="tg-0pky">내용</td>
                            <td className="tg-0pky" colSpan="5" ><input type={'text'} id = {'contentinput'} name={'contentinput'}/></td>
                        </tr>
                        </tbody>
                    </table>

                <input type = "button" value = "리스트로" ></input>
                <input type = "button" value = "글쓰기" onClick={() => submitArticleData(login_info, setarticletypeSelected)}></input>
             </>

    )

}


async function submitArticleData(login_info, setarticletypeSelected) {
    let articleTitle = document.getElementsByName('titleinput');
    let articleContent = document.getElementsByName('contentinput');
    articleTitle = articleTitle[0].value;
    articleContent = articleContent[0].value;

    const params = {
        'title' : articleTitle,
        'content' : articleContent,
        'writer' : login_info.user_type
    }
    const headers ={
        'Content-Type': 'application/json'
    }

    const URL = process.env.REACT_APP_SITEDATA_ARTICLE_WRITE || '/homekit/admin/article/write';
    let responseArticleData = null;
    try{
        responseArticleData = await axios.post(
            URL, params, { withCredentials: true});

        responseArticleData = responseArticleData.data;
        if(responseArticleData.error != '' || responseArticleData.error != null)
            setarticletypeSelected('OnedropCSArticleBoard');
        else{
            alert('글쓰기가 실패하였습니다. error code = \n', responseArticleData.error);
        }
    }catch{

    }

}