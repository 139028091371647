import {useContext, useEffect, useLayoutEffect, useState} from 'react';
import {Box, TextField, TextFieldwithBG, Button, Grid, FormControl, InputLabel, Select, Menu, MenuItem } from "__ui_components/UIComponents";

import SelectItem from "_components_public/SelectItem";
import {useGetBoardList, useFieldTestCenter, useGetBoardDetail} from "../__api/requestOneDropData";
import axios from "axios";
import articlemodify from "./articlemodify.css"
import {ArticleContext} from "./onedropCSArticleBoardMain";
import {SiteContext} from "../__context_api/SiteContext";
export default function ComponentArticleModify() {
    const { articletypeSelected, setarticletypeSelected } = useContext(ArticleContext);
    const { articleSeqSelected, setarticleSeqSelected } = useContext(ArticleContext);

    const { login_info, setlogin_info } = useContext(SiteContext);
    const [is_loading, BoardDetail] = useGetBoardDetail();
    //const [BoardList,setBoardList] = useState({});
   // let BoardList = null;

    //BoardList = await getBoardList();

    //useEffect(BoardList,[]);
    function isEmptyObj(obj)  {
        if(typeof obj === Object){
            if(obj.constructor === Object
                && Object.keys(obj).length === 0)  {
                return true;
            }
        }
        return false;
    }




    useEffect(() => {
        if(isEmptyObj(BoardDetail) != true){

        }; return () => null;},[ BoardDetail]);


    let boardDetail = BoardDetail.article;


    if (BoardDetail != null) {

        return (

            <>
                {(!is_loading) ?
                    <table className="tg">
                        <thead>
                        <tr>
                            <th className="tg-0pky">번호</th>
                            <th className="tg-0pky">{boardDetail[0].seq}</th>
                            <th className="tg-0pky">제목</th>
                            <th className="tg-0pky">{boardDetail[0].title}</th>
                            <th className="tg-0pky">날짜</th>
                            <th className="tg-0pky">{boardDetail[0].createdate}</th>
                            <th className="tg-0lax">작성자</th>
                            <th className="tg-0lax">{boardDetail[0].writer}</th>
                            <th className="tg-0lax">수정날짜</th>
                            <th className="tg-0lax">{boardDetail[0].modifieddate}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="tg-0pky">내용</td>
                            <td className="tg-0pky" colSpan="9">{boardDetail[0].content}</td>
                        </tr>
                        </tbody>
                    </table>
             : ''}
                <input type = "button" value = "리스트로" ></input>
                <input type = "button" value = "수정"></input>

            </>

    )


    }else {
        return null;
    }
}