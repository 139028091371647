
import React, { PureComponent } from 'react';
import {TEST_RESULT_TYPE} from "__config/dashboardConfig";

import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


export default function StackedBar_1 ({width=600, height=300, data, TEST_RESULT_TYPE})  {

  const byBar = (row) => <Bar key={row.idx} name={row.label} dataKey={row.idx} stackId="a" fill={TEST_RESULT_TYPE[row.idx].color} />
    //console.log('TEST_RESULT_TYPE in StackedBar_1',TEST_RESULT_TYPE);
  return (
    <div width="100%" height="100%">
      <BarChart
        width={width}
        height={height}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={false}/>
        <XAxis dataKey="label" />
        <YAxis hide={true}/>
        <Tooltip cursor={{fill: '#fff'}} />
        <Legend 
        formatter={(value, entry, index) => <span style={{color:'#000'}}>{`${value}`}</span>}/>
        {
          Object.entries(TEST_RESULT_TYPE).map( ([kk,vv]) => byBar(vv))
        }
        
      </BarChart>
    </div>
  );
      };

