

import { TEST_RESULT_TYPE } from '__config/dashboardConfig';
import { FaceIcon } from "__icons/IconProvider";
import { listOfTestResult, totalTestCountFromObject } from "__lib/cTools";

import React from 'react';

//import { useContext, DistributorContext } from '__context_api/DistributorContext';

import { Typography , Stack} from '@mui/material';
//import {Typographya} from "__ui_components/UIComponents";

import CardBasic  from  '_components_public/CardBasic';
import DounutChart_2  from '_components_chart/DounutChart_2';
import ChipsOfAllTestResult from "_components_public/ChipsOfAllTestResult";


const overallCountByTestResult = (result) => {
    const _gross = listOfTestResult(result);
    const tatal_test_cnt = totalTestCountFromObject(result);
    return _gross.map(row => (
        {
            idx : row.idx,
            label : TEST_RESULT_TYPE[row.idx].label,
            sub_total : row.cnt,
            content :  `${(row.cnt/tatal_test_cnt*100).toFixed(1)}% (${row.cnt})`,
            color :  TEST_RESULT_TYPE[row.idx].color,
            icon : <FaceIcon style={{color:'#666'}}/>,
        }));
};

const pieByTestResult = (result) => {
    const _gross = listOfTestResult(result);    
    return _gross.map(row => (
        {
            idx : row.idx,
            label : TEST_RESULT_TYPE[row.idx].label,
            value : row.cnt,
            color :  TEST_RESULT_TYPE[row.idx].color,
        }));
};

export default function CardDist01_TestResult( {location_analysis, start_date, end_date, sumOFFieldAnalysis, textLangAnalysisresultbox, TEST_RESULT_TYPE , mode} ) {

    //const { location_analysis, login_info }  = useContext(DistributorContext); 
    const test_result = location_analysis.result;

    const overall_count_by_test_result = overallCountByTestResult(test_result);

    const pie_by_test_result = React.useMemo( ()=> pieByTestResult(test_result), [test_result]);
    const total_test_count = totalTestCountFromObject(test_result);

    const CardBody = (sumOFFieldAnalysis != 0) ? (
        <div style={{width:'100%', height:'310px'}}>       
            <Stack direction="row" spacing={2}>
                <DounutChart_2 data ={pie_by_test_result} total_test_count={total_test_count} textLangAnalysisCard={textLangAnalysisresultbox}/>
                <div style={{padding:'15px 10px 0 25px',}}>
                    <ChipsOfAllTestResult rows={overall_count_by_test_result} height='180px' TEST_RESULT_TYPEset={TEST_RESULT_TYPE} mode={mode}/>
                </div>               
            </Stack>
        </div>)  :
    (<div style={{padding:'0 20px 0 20px'}}>
        <div style={{width: "420px", height: "260px", "vertical-align": "middle", display: "table-cell", "text-align": "center"}}>{textLangAnalysisresultbox.error1}</div>
    </div>);

    return (
        <>        
        <CardBasic card_title={textLangAnalysisresultbox.title} card_body={CardBody} style={{width:515, height:310}}/>
        </>

    )
}