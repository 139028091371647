
import ProgressBar from "@ramonak/react-progress-bar";
import { Typography, TextField, Button } from "@mui/material";
import {TypographyA} from "__ui_components/UIComponents";

// 'rgb(16,156,241,0.2)'

export default function ProgessBar_1 ( {total,  progress}) {
    const gross_prct = (total) ? Math.round(progress/total*100) : '-'; //1607(progress) /1484(total)

    return (
        <div style={{ padding:'0 0 0 0', }}>
        <TypographyA style={{letterSpacing:'2px', fontSize:50, fontWeight:700}}>{gross_prct}<span style={{fontSize:41}}>%</span></TypographyA>
        <div  align='left' style={{marginTop:-7,}}>

            <ProgressBar 
                completed={gross_prct} maxCompleted={100} 
                height='10px'
                bgColor='#109CF1' baseBgColor='#ccc' 
                borderRadius='5px'
                isLabelVisible = {false}
                animateOnRender = {true}
                />              
        </div>
        <Typography variant="h7" >{progress}/{total}</Typography>
        
        </div>
    );
};


