

import {TEST_RESULT_TYPE} from "__config/dashboardConfig";
import { subtotalOfAllTestResultType } from "__lib/cTools";
import { FaceIcon } from '__icons/IconProvider';
import { textSliced } from '__lib/aTools';

import { useContext, SiteContext } from '__context_api/SiteContext';

import { Typography , Stack, Grid} from '@mui/material'

import CardBasic  from  '_components_public/CardBasic';
import ChipsOfAllTestResult from "_components_public/ChipsOfAllTestResult";

const ListByChipGroup = ( { rows_by_segment, departments, TEST_RESULT_TYPEset, mode, sitecard }) => {
    let TEST_RESULT_TYPEsetobj = TEST_RESULT_TYPEset;
    return (
        <>
        <Grid container  spacing={1} style={{paddingLeft:'12px'}}>
            {
                rows_by_segment.map( (seg,index) => {
                    return (
                        <Grid item xs={2} key={index}>
                        <div style = {{paddingBottom:'15px'}}>                            
                            <ChipsOfAllTestResult rows={seg.rows} segment_name={seg.label} segment_color={departments[seg.idx] && departments[seg.idx].color} width='240px' TEST_RESULT_TYPEset={TEST_RESULT_TYPEsetobj} mode={mode} sitecard={sitecard}/>
                        </div>
                        </Grid>
                    )
                } )
            }
        </Grid>

        </>
    )
};

const testResultByDepartment = (list_by_department) => (
    list_by_department.map( 
        dep => (
            {
                idx : dep.idx,
                label : textSliced(dep.name, 19) , //dep.name.slice(0,15), 
                color : dep.color,
                rows : Object.entries(TEST_RESULT_TYPE).map (
                    ([kk,vv]) => 
                        {
                            const _subtotal = subtotalOfAllTestResultType(dep);
                            return {
                                idx : kk,
                                label : vv.label,
                                content : `${(dep[kk == "inconclusive" ? "indeterminate" : kk]/_subtotal*100).toFixed(0)}% | ${dep[kk == "inconclusive" ? "indeterminate" : kk]}`,
                                color : vv.color,
                                xicon : <FaceIcon style={{color:'#666'}}/>,
                            };
                        }
                ),
            }))
);

export default function CardUser04_TestProgess({field_analysis, list_by_department, sumOFFieldAnalysis, textLangSiteAnalysisCard, TEST_RESULT_TYPE,mode, sitecard}) {

    const { departments }  = useContext(SiteContext); 
    const test_result_by_department = testResultByDepartment(list_by_department);
    let TEST_RESULT_TYPEset = TEST_RESULT_TYPE;
    //console.log('TEST_RESULT_TYPEset223',TEST_RESULT_TYPEset);
    const CardBody =
        (sumOFFieldAnalysis != 0) ? (
        <div style={{width:'100%', height:'auto'}}>        
            <ListByChipGroup rows_by_segment = {test_result_by_department} departments={departments} TEST_RESULT_TYPEset={TEST_RESULT_TYPEset} mode={mode} sitecard={sitecard}/>
        </div> ) :
    (<div style={{padding:'0 20px 0 20px'}}>
        <div style={{width: "1008px", height: "240px", "vertical-align": "middle", display: "table-cell", "text-align": "center"}}>{textLangSiteAnalysisCard.error1}</div>
    </div>);
    

    return (
        <>
        <CardBasic card_title={textLangSiteAnalysisCard.title} card_body={CardBody} style={{width:1040, height:'auto'}}/>
        </>

    )
}