
import axios from 'axios';

export async function reqLogIn( username, password, remember_me=false) {
    //const URL = '/homekit/admin/api/login';

     let URL = process.env.REACT_APP_LOGIN_URL || '/homekit/admin/api/login';
    /*if(process.env.REACT_APP_NODE_ENV === "development"){
        URL = '/homekit/admin/api/login';
    }
    else{
        URL = process.env.REACT_APP_LOGIN_URL;
    }*/

    //const URL = process.env.REACT_APP_LOGIN_URL + '/homekit/admin/api/login';
    //const URL = 'https://testsrv.1drop.co.kr/homekit/admin/api/login';
       //const URL = 'https://homekit.1drop.co.kr/homekit/admin/api/login';
    const params = {
            username,
            password,
            remember_me,
    };
    // const headers = {
    //     'Content-Type': 'application/json'
    // };
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Request-Method':"post",
        'Access-Control-Request-Headers':"Content-Type,access-control-allow-credentials",
        'access-control-allow-credentials': "true"
    };
    try {
       let res = await axios(
        {
            method: 'post',
            url : URL,
            data : params    ,
            headers: headers,
            withCredentials : true,
        }
        );
       axios.defaults.withCredentials = true;

        if(res.status === 200){
            // test for status you want, etc

        }    
        // Don't forget to return something   
        return res.data
    }
    catch (err) {
        console.error(err);
        return 'error'
    }
};