//import { FaceIcon } from "__icons/IconProvider";

import React, {useState, useMemo, useEffect} from 'react';
import { useContext, DistributorContext } from '__context_api/DistributorContext';

import { 
    useLocationAnalysis, 
    } from "__api/requestOneDropDistributorData";

import {Box, TextField, TextFieldwithBG, Button, Grid, FormControl, InputLabel, Select, Menu, MenuItem } from "__ui_components/UIComponents";

//import CardBasic  from  '_components_public/CardBasic'

import SelectItem from "_components_public/SelectItem";
import SelectItemByLabel from "_components_public/SelectItemByLabel";
import MuiDatePicker from "_components_public/MuiDatePicker";

import CardDist01_TestResult from '_components_card/CardDist01_TestResult';
import CardDist02_CountOfUse from '_components_card/CardDist02_CountOfUse';
import CardDist03_TrendByPeriod from '_components_card/CardDist03_TrendByPeriod';
import CardDist04_UseBySeller from '_components_card/CardDist04_UseBySeller';
import UntilIsLoading from "_components_public/UntilIsLoading";

import { debugMsg } from "__lib/debugMsg";
const IS_LOCAL_DEBUG = false;
const DebugMsg = debugMsg(IS_LOCAL_DEBUG);


function InputFormSearchDist01({ date_to_select, vendor_to_select, vendor_to_select2, handleConfirm, textLangAnalysissearch}) {

    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [vendor, setVendor] = useState('');
    const [periodType, setPeriodType] = useState('weekly');
    const period_type_to_select = ['daily', 'weekly', 'monthly',];

    const onClickConfirm = () => {
        handleConfirm({
            date_from : dateFrom,
            date_to : dateTo,
            period_type : periodType,
            vendor : (vendor == "전체" || vendor == "All") ? "" : vendor,
        });
    };
    //console.log('vendor vendor',vendor);
    return (
        <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '200px'  },
          display : 'flex',
        }}
        noValidate
        autoComplete="off">
            <SelectItemByLabel input_label={textLangAnalysissearch.box1} value={vendor} item_to_select={vendor_to_select2}  handleChange={setVendor} is_set_all={true}  textLangAnalysissearch={textLangAnalysissearch} />
            <SelectItem input_label={textLangAnalysissearch.box2} value={periodType} item_to_select={period_type_to_select}  handleChange={setPeriodType} textLangSitesearhinput={textLangAnalysissearch} />

            <MuiDatePicker label={textLangAnalysissearch.box3} passValue={setDateFrom} />
            <MuiDatePicker label={textLangAnalysissearch.box4}  passValue={setDateTo} />
            {/*
            <SelectItem input_label='시작날짜' value={dateFrom} item_to_select={date_to_select}  handleChange={setDateFrom} />
            <SelectItem input_label='끝 날짜' value={dateTo} item_to_select={date_to_select}  handleChange={setDateTo} />
            */}
        <Button sx={{maxWidth:50}} variant='contained' onClick={()=>onClickConfirm()}>{textLangAnalysissearch.submit}</Button>
  
      </Box>
    );
};



export default function OneDropDis01Analysis ({defultlang, TEST_RESULT_TYPE}) {

    const { login_info, date_to_select, vendor_to_select, vendor_to_select2,location_vendor,lang, textOflang, setdefultlang }  = useContext(DistributorContext);
    const token = login_info.token; //login_info.token;
    let initial_params = {};
    //console.log('defultlang in OneDropDis01Analysis',defultlang);
    const [analysislang, setanalysislang] = useState(defultlang);
    const mode = "distributor";
    useEffect(() => {
        return () =>  null;

    },[])

    let textlang = textOflang[defultlang];
    let textLangdistributorAnalysis = textlang.distributor.analysis;
    if(login_info.distributor_code){
        if(login_info.distributor_code == 'onedrop'){
            initial_params = {
                "distributor_code": login_info.distributor_code ,
                "start_time": "2020/01/01",
                "end_time": "2023/12/31",
                "periodical_type": "weekly",
                "vendor_code_name" : undefined,
                "entries" : 10000,
                "page" : 0,
                "user_id_contains": '1drop_'

            }
        }else if(login_info.distributor_code == 'mediconhellas'){
            initial_params = {
                "distributor_code": login_info.distributor_code ,
                "start_time": "2020/01/01",
                "end_time": "2023/12/31",
                "periodical_type": "weekly",
                "vendor_code_name" : undefined,
                "entries" : 10000,
                "page" : 0,
                "user_id_contains": 'Hellas_'
            }
        }else if(login_info.distributor_code == 'biowings'){
            initial_params = {
                "distributor_code": login_info.distributor_code ,
                "start_time": "2020/01/01",
                "end_time": "2023/12/31",
                "periodical_type": "weekly",
                "vendor_code_name" : undefined,
                "entries" : 10000,
                "page" : 0,
                "user_id_contains": 'Biowings_'
            }
        }else{
            initial_params = {
                "distributor_code": login_info.distributor_code ,
                "start_time": "2020/01/01",
                "end_time": "2023/12/31",
                "periodical_type": "weekly",
                "vendor_code_name" : undefined,
                "entries" : 10000,
                "page" : 0,
                "user_id_contains": login_info.distributor_code + '_'
            }
        }
    }else{
        initial_params = {
            "distributor_code": undefined ,
            "start_time": "2020/01/01",
            "end_time": "2023/12/31",
            "periodical_type": "weekly",
            "vendor_code_name" : undefined,
            "entries" : 10000,
            "page" : 0,
        }
    }

    const [searchOption, setSearchOption] = useState({
        date_from : initial_params.start_time, //"2020/01/01",
        date_to : initial_params.end_time, //"2022/12/31",
        vendor : initial_params.vendor_code_name, //undefined,
    });

    const [params, setParams] = useState(initial_params);
    const [is_loading, location_analysis ] = useLocationAnalysis(params, token);
    //console.log('location_analysis',location_analysis);
    const onSetSearchOption = (vv) => {

        //const option_updated = {...searchOption, vv};
        setSearchOption(vv);
        setParams({
            ...params, 
            "start_time" : (vv.date_from !== '') ? vv.date_from : initial_params.start_time,
            "end_time" : (vv.date_to !== '') ? vv.date_to : initial_params.end_time ,
            "periodical_type" : (vv.period_type !== '') ? vv.period_type : initial_params.periodical_type ,
            "vendor_code_name" : (vv.vendor) ? vv.vendor : initial_params.vendor_code_name,
        });
    };


    const location_analysisResult = location_analysis.result != undefined ? location_analysis.result : {};
    const sumOFFieldAnalysis = location_analysisResult.cancel +  location_analysisResult.inconclusive + location_analysisResult.invalid +  location_analysisResult.negative +  location_analysisResult.positive;
    return (
        <>

        <div style={{paddingBottom:20}}>
        <InputFormSearchDist01 
            date_to_select={date_to_select} 
            vendor_to_select={vendor_to_select}
            vendor_to_select2={vendor_to_select2}
            handleConfirm = {onSetSearchOption}
            textLangAnalysissearch = {textLangdistributorAnalysis.serchinputbox}

        />
        </div>
        <div style={{maxWidth:'800px', padding:"0 0 10px 0"}} >
            <DebugMsg data = {location_analysis} label='Distributor01Analysis:location_analysis=' />

        </div>
        <UntilIsLoading is_loading={ is_loading}  loading_msg={'connecting data server...'} >
        <Grid container spacing={1}  >
        
            <Grid item>
                <CardDist01_TestResult location_analysis={location_analysis} sumOFFieldAnalysis={sumOFFieldAnalysis} textLangAnalysisresultbox = {textLangdistributorAnalysis.resultbox.box1} TEST_RESULT_TYPE={TEST_RESULT_TYPE} mode={mode}/>
            </Grid>
            <Grid item>
                <CardDist02_CountOfUse location_analysis={location_analysis} sumOFFieldAnalysis={sumOFFieldAnalysis} textLangAnalysisresultbox = {textLangdistributorAnalysis.resultbox.box2}/>
            </Grid>
            <Grid item>
                <CardDist03_TrendByPeriod location_analysis={location_analysis} sumOFFieldAnalysis={sumOFFieldAnalysis} textLangAnalysisresultbox = {textLangdistributorAnalysis.resultbox.box3}/>
            </Grid>
            <Grid item>
                <CardDist04_UseBySeller location_analysis={location_analysis} sumOFFieldAnalysis={sumOFFieldAnalysis} textLangAnalysisresultbox = {textLangdistributorAnalysis.resultbox.box4}/>
            </Grid>
        </Grid>
        </UntilIsLoading>
        </>
    )
}