

const is_global_debug = false;

export function debugMsg (is_local_dedug=false) {
    return ({data, label=''}) => {
        return (
            (is_global_debug && is_local_dedug ) 
            ? (
                <div>
                    <div style={{color:'blue'}}> {label} </div>
                    <div style={{color:'#78281f', padding:'0 0px 0 px', justifyContent:'flex-start', wordBreak:'break-all'}}>
                        {JSON.stringify(data)} 
                    </div>
                </div>
            )
            : ''
        );
    };
};



