
import {createContext, useContext} from "react";

/*
import { 
    useLocationAnalysis, 
    useLocationMapAnalysisDetail, 
    useLocationMapAnalysis, 
    useLocationMapDot,
    useLocationVender
    } from "__api/requestDistributorData";
*/

export const DistributorContext = createContext(
    {
        location_analysis : {},       
        location_map_analysis_detail : {}, 
        location_map_analysis : {}, 
        login_info : {},
    }
);

export const DistributorContextProvider = DistributorContext.Provider;
export { useContext } ;










