

export function randomColor () {

    const range = 0x50;
    const add_up = 0x90;
    const r =  Math.round((Math.random() *range) + add_up).toString(16);
    const g =  Math.round((Math.random() *range) + add_up).toString(16);
    const b =  Math.round((Math.random() *range) + add_up).toString(16);
    return `#${r}${g}${b}`;

    

    // Math.round((Math.random() * 0x300000) + 0x300000).toString(16), //'#F16363',

};


export function getDaysArray (start, end) {
    for(var arr=[],dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)){
        arr.push(new Date(dt));
    }
    return arr;
};


export function randomBetween (n1, n2) {
    return Math.floor(Math.random() * (n2-n1))+ n1;
};


export function sum(arr) {
    return arr.reduce((psum,p)=> psum+p, 0);
};


export function sleep(ms) {
    const wakeUpTime = Date.now() + ms;
    while (Date.now() < wakeUpTime) {}
  };


export function textSliced(label, nn) {
    return (label.length < nn) ? label : `${label.slice(0,nn-2)}..`    
};