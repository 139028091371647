//import { Typography , Stack} from '@mui/material'

import CardBasic  from  '_components_public/CardBasic';
import AreaChart_1 from "_components_chart/AreaChart_1";

const positiveCntByPeriod = (list_by_period) => {
    return list_by_period.map( row => (
        {
            x : row.date.slice(2), //row.date,
            value : row.positive,
            x_label : row.date.slice(0,10),
            by_department : row.departmemt,
            description : JSON.stringify(row.departmemt),
        }
    ));
};

export default function CardUser05_TestResultTrend({field_analysis, list_by_period, sumOFFieldAnalysis, textLangSiteAnalysisCard }) {

    const positive_cnt_by_period = positiveCntByPeriod(list_by_period);
    const CardBody =
        (sumOFFieldAnalysis != 0) ? (
        <div style={{padding:'0 20px 0 20px'}}>        
            <div
            style={{
                position: 'absolute',
                bottom: 0,
                left: 15,
                }}
            >
                <AreaChart_1 width={1000} data={positive_cnt_by_period} line_type='monotoneX'  dot={false}/>
            </div>
        </div>)   :
    (<div style={{padding:'0 20px 0 20px'}}>
        <div style={{width: "1008px", height: "240px", "vertical-align": "middle", display: "table-cell", "text-align": "center"}}>{textLangSiteAnalysisCard.error1}</div>
    </div>);


    return (
        <>
         <CardBasic card_title={textLangSiteAnalysisCard.title} card_body={CardBody} style={{position: 'relative', width:1040, height:304}}/>
        </>
    )
}