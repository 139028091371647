
import React, {useEffect, useState} from 'react';

import { useContext, SiteContext } from '__context_api/SiteContext';

import { 
    useFieldDepartment, 
    useFieldAnalysis, 
    useFieldTestCenter, 
    useFieldStaff,
    } from "__api/requestOneDropData";


import {Box, TextField, TextFieldwithBG, Button, Grid, FormControl, InputLabel, Select, Menu, MenuItem } from "__ui_components/UIComponents";

import SelectItem from "_components_public/SelectItem";
import SelectItemByLabel from "_components_public/SelectItemByLabel";
import MuiDatePicker from "_components_public/MuiDatePicker";

import CardUser01_TestResult from '_components_card/CardUser01_TestResult';
import CardUser02_UseCount from '_components_card/CardUser02_UseCount';
import CardUser03_ResultByPeriod from '_components_card/CardUser03_ResultByPeriod';
import CardUser04_TestProgess from '_components_card/CardUser04_TestProgess'
import CardUser05_TestResultTrend from '_components_card/CardUser05_TestResultTrend'
import CardUser06_TestCoverage from '_components_card/CardUser06_TestCoverage'
import UntilIsLoading from "_components_public/UntilIsLoading";

import { debugMsg } from "__lib/debugMsg";
import {TEST_RESULT_TYPE} from "../__config/dashboardConfig";
const IS_LOCAL_DEBUG = false;
const DebugMsg = debugMsg(IS_LOCAL_DEBUG);

 

function InputFormSearchSite01({ date_to_select, department_to_select, department_to_select2, handleConfirm,textLangSiteAnalysissearch}) {

    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [department, setDepartment] = useState('');
    const [periodType, setPeriodType] = useState('weekly');
    const period_type_to_select = ['daily', 'weekly', 'monthly',];

    const onClickConfirm = () => {
        handleConfirm({
            date_from : dateFrom,
            date_to : dateTo,
            department : department,
            period_type : periodType,
        });
    };
    //console.log('textLangSiteAnalysissearch111',textLangSiteAnalysissearch);
    return (
        <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '200px'  },
          display : 'flex',
        }}
        noValidate
        autoComplete="off">
            <SelectItemByLabel input_label={textLangSiteAnalysissearch.box1} value={department} item_to_select={department_to_select2}  handleChange={setDepartment} textLangAnalysissearch={textLangSiteAnalysissearch}/>
            <SelectItem input_label={textLangSiteAnalysissearch.box2} value={periodType} item_to_select={period_type_to_select}  handleChange={setPeriodType} />
            <MuiDatePicker label={textLangSiteAnalysissearch.box3} passValue={setDateFrom} />
            <MuiDatePicker label={textLangSiteAnalysissearch.box4} passValue={setDateTo} />
            {/*
            <SelectItem input_label='시작날짜' value={dateFrom} item_to_select={date_to_select}  handleChange={setDateFrom} />
            <SelectItem input_label='끝 날짜' value={dateTo} item_to_select={date_to_select}  handleChange={setDateTo} />
            */}
        <Button sx={{maxWidth:50}} variant='contained' onClick={()=>onClickConfirm()}>{textLangSiteAnalysissearch.submit}</Button>
  
      </Box>
    );
};





const listByPeriod = (field_analysis) => {
    if (!field_analysis || !field_analysis.periodical || Object.keys(field_analysis.periodical).length===0) {
        return [];
    };
    return Object.entries(field_analysis.periodical).map( ([kk,vv]) => (
        {
            ...vv,
            date : kk,  
            periodical_type :  field_analysis.periodical_type,   
        }
        )).sort((a, b) => +(a.date > b.date) || -(a.date) < b.date);
};

const listByDepartment = (field_analysis) => {
    if (!field_analysis || !field_analysis.department || Object.keys(field_analysis.department).length===0) {
        return [];
    };
    return Object.entries(field_analysis.department).map( ([kk,vv]) => (
        {
            ...vv,
            idx : kk,  
            site_key :  field_analysis.site_key,   
        }
        ));
};




export default function OneDrop01Analysis ({defultlang,TEST_RESULT_TYPE}) {

    let { login_info, field_department, date_to_select, department_to_select, department_to_select2, departments, lang, textOflang }  = useContext(SiteContext);
    const token = login_info.token;
    let initial_params = {}
    //const [analysislang,setanalysislang] = useState(""defultlang"");
    //console.log('defultlang in OneDrop01Analysis11',defultlang);
    //console.log('TEST_RESULT_TYPE in OneDrop01Analysis',TEST_RESULT_TYPE);



    useEffect(() => {


    },[]);
    let textlang = textOflang[defultlang];
    let textLangSiteAnalysis = textlang.site.analysis;
    //console.log('textLangSiteAnalysis',textLangSiteAnalysis);
    //console.log('defultlang in OneDrop01Analysis22',defultlang);
    const mode = "site";
    if(login_info.site_key){

        if(login_info.site_key == 'onedrop'){
            initial_params = {
                "site_key": login_info.site_key, //"samsung",
                "department_code": undefined,
                "start_time":"2020/01/01",
                "end_time":"2023/12/31",
                "periodical_type":"weekly",
                "user_id_contains" : '1drop_'
            }
        }else{
            initial_params = {
                "site_key": login_info.site_key, //"samsung",
                "department_code": undefined,
                "start_time":"2020/01/01",
                "end_time":"2023/12/31",
                "periodical_type":"weekly",
                "user_id_contains": login_info.site_key + '_'
            }
        }
    }else{
        initial_params = {
        "site_key": undefined, //"samsung",
        "department_code": undefined,
        "start_time":"2020/01/01",
        "end_time":"2023/12/31",
        "periodical_type":"weekly",
    }


    }
    let departmentOfFieldDepartment = {};
    if(department_to_select == {}){


        department_to_select2 = departmentOfFieldDepartment;

    }
    const [searchOption, setSearchOption] = useState({
        date_from : initial_params.start_time, //"2020/01/01",
        date_to : initial_params.end_time, //"2022/12/31",
        department : initial_params.department_code, //undefined,
    });

    const [params, setParams] = useState(initial_params);
    const [is_loading, field_analysis] = useFieldAnalysis(params, token);


  
    const list_by_period = listByPeriod(field_analysis);
    const list_by_department = listByDepartment(field_analysis);

    const onSetSearchOption = (vv) => {

        //const option_updated = {...searchOption, vv};
        setSearchOption(vv);
        setParams({
            ...params, 
            "department_code" : (vv.department) ? vv.department : initial_params.department_code,
            "start_time" : (vv.date_from !== '') ? vv.date_from : initial_params.start_time,
            "end_time" : (vv.date_to !== '') ? vv.date_to : initial_params.end_time ,
            "periodical_type" : (vv.period_type !== '') ? vv.period_type : initial_params.periodical_type ,            
        });
    };
    const field_analysisResult = field_analysis.result != undefined ? field_analysis.result : {};

    const sumOFFieldAnalysis = field_analysisResult.cancel +  field_analysisResult.inconclusive + field_analysisResult.invalid +  field_analysisResult.negative +  field_analysisResult.positive;

    return (
        <>

        <div style={{paddingBottom:20}}>
        <InputFormSearchSite01
            date_to_select={date_to_select} 
            department_to_select={department_to_select} 
            department_to_select2 = {department_to_select2}
            handleConfirm = {onSetSearchOption}
            textLangSiteAnalysissearch = {textLangSiteAnalysis.serchinputbox}
        />
        </div>
        <div style={{maxWidth:'800px', padding:"0 0 10px 0"}} >
            <DebugMsg data = {field_analysis} label='EndUser01Analysis:field_analysis=' />
        </div>
        <UntilIsLoading is_loading={  is_loading}  loading_msg={'connecting data server...'} >
        <Grid container spacing={1} direction='row' >
            <Grid item>
                <CardUser01_TestResult field_analysis={field_analysis} list_by_period={list_by_period} list_by_department={list_by_department}  sumOFFieldAnalysis={sumOFFieldAnalysis} textLangSiteAnalysisCard ={textLangSiteAnalysis.resultbox.box1} TEST_RESULT_TYPE={TEST_RESULT_TYPE} mode={mode} sitecard={"01"}/>
            </Grid>
            <Grid item>
                <CardUser02_UseCount  field_analysis={field_analysis}  list_by_period={list_by_period} list_by_department={list_by_department}  sumOFFieldAnalysis={sumOFFieldAnalysis} textLangSiteAnalysisCard ={textLangSiteAnalysis.resultbox.box2}/>
            </Grid>
            <Grid item>
                <CardUser03_ResultByPeriod  field_analysis={field_analysis}  list_by_period={list_by_period}  sumOFFieldAnalysis={sumOFFieldAnalysis} textLangSiteAnalysisCard ={textLangSiteAnalysis.resultbox.box3} TEST_RESULT_TYPE={TEST_RESULT_TYPE} TEST_RESULT_TYPE={TEST_RESULT_TYPE}/>
            </Grid>
            <Grid item>
                <CardUser04_TestProgess   field_analysis={field_analysis}  list_by_period={list_by_period}  list_by_department={list_by_department} sumOFFieldAnalysis={sumOFFieldAnalysis} textLangSiteAnalysisCard ={textLangSiteAnalysis.resultbox.box4} TEST_RESULT_TYPE={TEST_RESULT_TYPE} mode={mode} sitecard={"04"}/>
            </Grid>
            <Grid item>
                <CardUser05_TestResultTrend  field_analysis={field_analysis}  list_by_period={list_by_period}  sumOFFieldAnalysis={sumOFFieldAnalysis} textLangSiteAnalysisCard ={textLangSiteAnalysis.resultbox.box5}/>
            </Grid>
            <Grid item>
                <CardUser06_TestCoverage   field_analysis={field_analysis}   list_by_period={list_by_period} list_by_department={list_by_department} sumOFFieldAnalysis={sumOFFieldAnalysis} textLangSiteAnalysisCard ={textLangSiteAnalysis.resultbox.box6}/>
            </Grid>
        </Grid>
        </UntilIsLoading>
        </>
    )
}