import {useContext, useEffect, useLayoutEffect, useState} from 'react';
import {Box, TextField, TextFieldwithBG, Button, Grid, FormControl, InputLabel, Select, Menu, MenuItem } from "__ui_components/UIComponents";

import SelectItem from "_components_public/SelectItem";
import {useGetBoardList, useFieldTestCenter, useGetBoardDetail} from "../__api/requestOneDropData";
import axios from "axios";
import {ArticleContext} from "./onedropCSArticleBoardMain";

export default function ComponentArticleDetail(seq) {
    const { articletypeSelected, setarticletypeSelected } = useContext(ArticleContext);
    const { articleSeqSelected, setarticleSeqSelected } = useContext(ArticleContext);
    //let BoardList = null;
    const [is_loading, BoardDetail] = useGetBoardDetail(articleSeqSelected);

    //const [BoardList,setBoardList] = useState({});
   // let BoardList = null;
    //BoardList = await getBoardList();

    //useEffect(BoardList,[]);
    function isEmptyObj(obj)  {
        if(typeof obj === Object){
            if(obj.constructor === Object
                && Object.keys(obj).length === 0)  {
                return true;
            }
        }
        return false;
    }



    useEffect(() => {
        if(isEmptyObj(BoardDetail) != true){

        }; return () => null;},[ BoardDetail]);


       let boardDetail = BoardDetail.article;

    if (boardDetail != null) {

        return (

            <>
                {(!is_loading) ?
                    <table className="tg">
                        <thead>
                        <tr>
                            <th className="tg-0pky">번호</th>
                            <th className="tg-0pky">{boardDetail[0].seq}</th>
                            <th className="tg-0pky">제목</th>
                            <th className="tg-0pky">{boardDetail[0].title}</th>
                            <th className="tg-0pky">날짜</th>
                            <th className="tg-0pky">{boardDetail[0].createdate}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="tg-0pky">데이터</td>
                            <td className="tg-0pky" colSpan="5">{boardDetail[0].content}</td>
                        </tr>
                        </tbody>
                    </table>

             : ''}
                <input type = "button" value = "리스트로" ></input>
                <input type = "button" value = "수정" onClick={() =>{setarticleSeqSelected(boardDetail[0].seq); setarticletypeSelected('OnedropCSArticleBoardDetail');}}></input>
                <input type = "button" value = "삭제" onClick={() =>{getdelete(boardDetail[0].seq, setarticletypeSelected)}}></input>
            </>
//setarticletypeSelected('OnedropCSArticleBoardDetail');}
    )


    }else {
        return null;
    }
}

 async function getdelete(seqParam, setarticletypeSelected){

    const URL = process.env.REACT_APP_SITEDATA_ARTICLE_DELETE || 'testsrv.1drop.co.kr';
    const PARAM = {
        "seq" : seqParam
    }
    let deleteResult = null;
    try{
        deleteResult = await axios.post(URL,PARAM,{withCredentials: true});

        if(deleteResult.status === 200){
            deleteResult = deleteResult.data;
            if(deleteResult == undefined){alert('삭제할 데이터가 없습니다.');}
            if(deleteResult.deleteResult === 1){
                alert('삭제 되었습니다.');
                setarticletypeSelected('OnedropCSArticleBoard');}
            }else{
                alert('삭제할 데이터가 없습니다.');
            }


    }catch{
        alert('삭제가 되지 않았습니다.');
    }



}