import {langUser} from "../main/OnedropUser"
import {langDisUser} from "../main/OnedropDisUser"
import {SiteContext, useContextSite} from "../__context_api/SiteContext";
import {DistributorContext} from "../__context_api/DistributorContext";
import {useContext, useState} from "react";



export function TestResultTypeChangeSite2({defaultlang}) {

    const {login_info, lang, textOflang} = useContext(SiteContext);
    //const [analysislang, setanalysislang] = useState(lang);
    let textlang = textOflang[defaultlang];
    //console.log('defaultlang',defaultlang);
    let testresulttype = textlang.testresulttype;
    //console.log('testresulttype',testresulttype);
    TEST_RESULT_TYPE ={
        'negative'  : {idx : 'negative' , label : testresulttype.label1, color : '#76F082',},//0
        'positive' : {idx : 'positive' , label : testresulttype.label2,  color : '#F16363',},//1
        'inconclusive' : {idx : 'inconclusive' ,label : testresulttype.label3,  color : '#FFD600',},//2
        'invalid' : {idx : 'invalid' ,label : testresulttype.label3,color : '#C1C1C1',},//3
        'cancel' : {idx : 'cancel' ,label : testresulttype.label4, color : '#EBEFF2',},//4
        //'incomplete' : {idx : 'incomplete' ,label : '미완료', color : '#EBEFF2',} //5
    }
    //console.log('TEST_RESULT_TYPE',TEST_RESULT_TYPE);

}
export let TEST_RESULT_TYPE = {
    'negative'  : {idx : 'negative' , label : '음성', color : '#76F082',},//0
    'positive' : {idx : 'positive' , label : '양성',  color : '#F16363',},//1
    'inconclusive' : {idx : 'inconclusive' ,label : '불확정',  color : '#FFD600',},//2
    'invalid' : {idx : 'invalid' ,label : '무효',color : '#C1C1C1',},//3
    'cancel' : {idx : 'cancel' ,label : '취소', color : '#EBEFF2',},//4
    //'incomplete' : {idx : 'incomplete' ,label : '미완료', color : '#EBEFF2',} //5
};

