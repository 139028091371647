

import React from 'react';
import {BrowserRouter, Route, Routes, Link, Router} from 'react-router-dom';

import LogInGateway from "login/LogInGateway";
import SignIn from "login/SignIn";
//import DistributorUser from "main/DistributorUser";
//import SiteUser from "main/SiteUser";
import OnedropUser from "./OnedropUser";
import OnedropDisUser from "./OnedropDisUser";


export default function Entry ({user}) {


    return (
        <>
            <Route
                exact
                path="/"
                render={() => (
                    <>
                    <LogInGateway >
                        <SignIn />
                    </LogInGateway>
                    </>
                )

            }

            /> 
            <Route
                exact
                path="/signin"
                render={() => (
                    <>                                       
                    <SignIn/>                   
                    </>
                )}
            />
            <Route
                exact
                path="/distributor"
                render={() => (
                    <>
                        <LogInGateway user_type='distributor'>
                            {/*<DistributorUser />*/}
                            <OnedropDisUser />
                        </LogInGateway>
                    </>
                )}
            />
            {/*<Route*/}
            {/*    exact*/}
            {/*    path="/onedropdistributor"*/}
            {/*    render={() => (*/}
            {/*        <>*/}
            {/*            <LogInGateway user_type='onedropdistributor'>*/}
            {/*                <OnedropDisUser />*/}
            {/*            </LogInGateway>*/}
            {/*        </>*/}
            {/*    )}*/}
            {/*/>*/}
            <Route
                exact
                path="/site"
                render={() => (
                    <>
                        <LogInGateway user_type='site'>
                            {/*<SiteUser/>*/}
                            <OnedropUser/>
                        </LogInGateway>
                    </>
                )}
            />
        {/*    <Route*/}
        {/*    exact*/}
        {/*    path="/onedrop"*/}
        {/*    render={() => (*/}
        {/*        <>*/}
        {/*            <LogInGateway user_type='onedrop'>*/}
        {/*                <OnedropUser/>*/}
        {/*            </LogInGateway>*/}
        {/*        </>*/}
        {/*    )}*/}
        {/*/>*/}

            {/*<Route*/}
            {/*    exact*/}
            {/*    path="/csarticle"*/}
            {/*    render={() => (*/}
            {/*        <>*/}
            {/*            <LogInGateway user_type='onedrop'>*/}
            {/*                <Csarticle/>*/}
            {/*            </LogInGateway>*/}
            {/*        </>*/}
            {/*    )}*/}
            {/*/>*/}

        </>
    )
};

