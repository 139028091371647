
//import { Typography , Stack} from '@mui/material'
import CardBasic  from  '_components_public/CardBasic';
import StackedBar_1 from "_components_chart/StackedBar_1";

const testResultByPeriodByType = (list_by_period) => {
    return list_by_period.map( 
        row => (
            {
                label : row.date.slice(2), //row.date,
                "negative": row.negative,
                "positive": row.positive,
                "inconclusive": row.inconclusive,
                "invalid": row.invalid,
                "cancel": row.cancel,
            }
        ));
};

export default function CardUser03_ResultByPeriod( {field_analysis, list_by_period, result_by_period, sumOFFieldAnalysis, textLangSiteAnalysisCard,TEST_RESULT_TYPE}) {

    const test_result_by_period_by_type = testResultByPeriodByType(list_by_period);

    //console.log('TEST_RESULT_TYPE in CardUser03_ResultByPeriod', TEST_RESULT_TYPE);

    const CardBody =
        (sumOFFieldAnalysis != 0) ? (
        <div style={{padding:'0 20px 0 20px'}}>        
            <div
            style={{
                position: 'absolute',
                bottom: 10,
                left: 15,
                }}
            >
                <StackedBar_1 width={1020} height={250} data={test_result_by_period_by_type} TEST_RESULT_TYPE={TEST_RESULT_TYPE}/>
            </div>
        </div>
    ) :
            (<div style={{padding:'0 20px 0 20px'}}>
                <div style={{width: "1008px", height: "240px", "vertical-align": "middle", display: "table-cell", "text-align": "center"}}>{textLangSiteAnalysisCard.error1}</div>
            </div>);

    return (
        <>
        <CardBasic card_title={textLangSiteAnalysisCard.title} card_body={CardBody} style={{position: 'relative', width:1040, height:304}}/>
        </>

    )


}