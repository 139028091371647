import {useContext, useEffect, useLayoutEffect, useState} from 'react';
import {
    Box,
    TextField,
    TextFieldwithBG,
    Button,
    Grid,
    FormControl,
    InputLabel,
    Select,
    Menu,
    MenuItem,
    TypographyA
} from "__ui_components/UIComponents";

import SelectItem from "_components_public/SelectItem";
import {useGetBoardList, useFieldTestCenter} from "../__api/requestOneDropData";
import axios from "axios";
import OnedropCSArticleBoardDetail from "./onedropCSArticleBoardDetail"
import Onedrop03TestCenter from "./oneDrop03TestCenter";
import {MapIcon, PersonOutlineIcon} from "../__icons/IconProvider";
import Onedrop02Employee from "./oneDrop02Employee";
import ComponentArticleDetail from "../containers/onedropCSArticleBoardDetail"
import OnedropCsArticleBoardDetail from "../containers/onedropCSArticleBoardDetail"

import ARTICLE_ITEMS from "../main/SideNavigation"
import {ArticleContext} from "./onedropCSArticleBoardMain";




export default function ComponentArticleList() {
    const { articletypeSelected, setarticletypeSelected,articleSeqSelected, setarticleSeqSelected } = useContext(ArticleContext);
    const [is_loading, BoardList] = useGetBoardList();

    //const [BoardList,setBoardList] = useState({});
   // let BoardList = null;

    //BoardList = await getBoardList();

    //useEffect(BoardList,[]);

    function setarticleSeqSelectedFun(setarticleSeqSelected,seq){
        setarticleSeqSelected(seq);
        return null;
    }

    function isEmptyObj(obj)  {
        if(typeof obj === Object){
            if(obj.constructor === Object
                && Object.keys(obj).length === 0)  {
                return true;
            }
        }
        return false;
    }


    useEffect(() => {
        if(isEmptyObj(BoardList) != true){

        }; return () => null;},[ BoardList]);


       let boardlist = BoardList.articlelist;
    if (boardlist != null && boardlist.length > 0) {

        return (

            <>
                {(!is_loading) ?


                <table className="tg">
                    <thead>
                    <tr>
                        <th className="tg-0lax">번호</th>
                        <th className="tg-0lax">제목</th>
                        <th className="tg-0lax">글쓴이</th>
                        <th className="tg-0lax">날짜</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        boardlist.map((list) =>
                    <tr>
                        <td className="tg-0lax"><div onClick={() => {setarticleSeqSelectedFun(setarticleSeqSelected, list.seq); setarticletypeSelected('OnedropCsArticleBoardDetail');}}>{list.seq}</div></td>
                        <td className="tg-0lax"><div onClick={() => {setarticleSeqSelectedFun(setarticleSeqSelected, list.seq); setarticletypeSelected('OnedropCsArticleBoardDetail');}}>{list.title}</div></td>
                        <td className="tg-0lax"><div onClick={() => {setarticleSeqSelectedFun(setarticleSeqSelected, list.seq); setarticletypeSelected('OnedropCsArticleBoardDetail');}}>{list.writer}</div></td>
                        <td className="tg-0lax"><div onClick={() => {setarticleSeqSelectedFun(setarticleSeqSelected, list.seq); setarticletypeSelected('OnedropCsArticleBoardDetail');}}>{list.createdate}</div></td>
                    </tr>
                        )
                    }
                    </tbody>
                </table>

             : ''}
                <input type = "button" value = "리스트로" ></input>
                <input type = "button" value = "글쓰기" onClick={() => setarticletypeSelected('OnedropCSArticleBoardWrite')}></input>
                <input type = "button" value = "삭제"></input>

            </>

    )


    }else {
        return (
            <>



                    <table className="tg">
                        <thead>
                        <tr>
                            <th className="tg-0lax">번호</th>
                            <th className="tg-0lax">제목</th>
                            <th className="tg-0lax">글쓴이</th>
                            <th className="tg-0lax">날짜</th>
                        </tr>
                        </thead>
                        <tbody>


                                <tr>
                                    <td className="tg-0lax" colSpan={4}><div>테이블의 데이터가 없습니다.</div></td>
                                </tr>


                        </tbody>
                    </table>



                <input type = "button" value = "리스트로" ></input>
                <input type = "button" value = "글쓰기" onClick={() => setarticletypeSelected('OnedropCSArticleBoardWrite')}></input>
                <input type = "button" value = "삭제"></input>


            </>
        );
    }
}