
//import axios from 'axios';
import { useState, useEffect } from "react";
import {  requestPostIgnoreSSL, requestPost, requestPostWithCredentials } from "__lib/httpRequest";



const parseData = (data) => {    
    return data;
};

export function useRequestAPI2 (url, params, headers, render_key='none' ) { 

    const [rtn, setRtn] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const callback = (data) => {

        setRtn(parseData(data));   
        setIsLoading(false);
    };

    useEffect(()=>{
        //const URL = 'https://3ex248wwk2.execute-api.ap-northeast-2.amazonaws.com/default/fetch_from_onedrop_api';
        setIsLoading(true);
        requestPostWithCredentials(url, params, headers, callback);          

    },[render_key]);
    return [isLoading, rtn] ;
};