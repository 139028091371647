import {
    useFieldDepartment,
    useFieldAnalysis,
    useFieldTestCenter,
    useFieldStaff,
} from "__api/requestOneDropData";

import {TEST_RESULT_TYPE} from "__config/dashboardConfig";

import {useEffect, useState} from 'react';
import {Box, TextField, TextFieldwithBG, Button, Grid, FormControl, InputLabel, Select,  MenuItem } from "__ui_components/UIComponents";

import TableEmployeeOnedrop from '_components_table/TableEmployeeOnedrop';
import SelectItem from "../_components_public/SelectItem";
import {SiteContext, useContext} from "../__context_api/SiteContext";



function InputFormSearchDist02({ handleConfirm,department_to_select, textLangSitesearhinput}) {

    const [department, setDepartment] = useState('');
    const [person, setPerson] = useState('');
    const onClickConfirm = () => {
        handleConfirm({
            department : department == "전체" ? "" : department,
            person : person,
        });
    };

    const handleOnKeyPress = e =>{
        if(e.key === 'Enter') {
            onClickConfirm();
        }
    }

    return (
        <Box
            component="form"
            sx={{
                '& > :not(style)': { m: 1, width: '200px'  },
                display : 'flex',
            }}
            noValidate
            autoComplete="off">

            <SelectItem input_label={textLangSitesearhinput.box1} value={department} item_to_select={department_to_select}  handleChange={setDepartment} is_set_all={true} textLangSitesearhinput={textLangSitesearhinput}/>
            <TextFieldwithBG size="small" id="outlined-basic" label={textLangSitesearhinput.box2} variant="outlined" onKeyPress={(e)=>handleOnKeyPress(e)} onChange={(e)=> setPerson(e.target.value)}   />
            <Button sx={{maxWidth:50}} variant='contained' onClick={()=>onClickConfirm()}>{textLangSitesearhinput.submit}</Button>
        </Box>

    );
};

const testResultByEmployee = (result) => {
    if (!result || Object.keys(result).length===0) {
        return [];
    };
    const test_result_map = Object.keys(TEST_RESULT_TYPE);

    return result.map(
        row => (
            {
                employee_code : row.staff_code,
                name : row.staff_name || '',
                department : row.department_name || '',
                position : row.job_title ||  '',
                recent_test_result : test_result_map[row.result_code] ,
                gender : (row.gender===1) ? 'male' : (row.gender===0) ? 'female' : 'unknown',
                contact : 'Contact' ,
                phone: row.phone ? row.phone : undefined,
                email: row.email ? row.email : undefined
            }
        ));
};

const filterByDepartmentAndPerson = (_list, dep, per) => _list.filter(
    row => (
        (!dep || row.department.includes(dep))
        && (!per || row.name.includes(per) || row.employee_code.includes(per))
    )
);

const test_result_mapFiltered = (test_result_by_employee, filterPosition, filterGender,filterRecentTestResult) => test_result_by_employee.filter((x) => {

    if (( (filterPosition === "전체" && filterGender === "전체" && filterRecentTestResult === "전체") || ( ((filterPosition === "전체") || (filterPosition === "All")) && filterGender === "All" && filterRecentTestResult === "All")  ) && x !== null) {
        return x;
    }

    if(filterRecentTestResult == "양성" || filterRecentTestResult == "Positive") {
        filterRecentTestResult = "positive";
    } else if (filterRecentTestResult == "음성" || filterRecentTestResult == "Negative") {
        filterRecentTestResult = "negative";
    } else if (filterRecentTestResult == "불확정" || filterRecentTestResult == "inconclusive") {
        filterRecentTestResult = "inconclusive";
    } else if (filterRecentTestResult == "무효" ||filterRecentTestResult == "Invalid") {
        filterRecentTestResult = "invalid";
    } else if (filterRecentTestResult == "취소" ||filterRecentTestResult == "Cancle") {
        filterRecentTestResult = "cancel";
    }

    if (filterPosition === -1 && filterGender === -1 && filterRecentTestResult === -1 && x !== null) {
        return x;
    }

    if(filterPosition == ""){
        filterPosition = "전체";
    }
    if(filterGender == ""){
        filterGender = "전체";
    }
    if(filterRecentTestResult == ""){
        filterRecentTestResult = "전체";
    }

    if (filterPosition === "전체" || filterPosition === "전체") {

        if (filterGender === "전체") {
            if (filterRecentTestResult === "전체" || filterRecentTestResult === "All") {
                return x;
            } else if (filterRecentTestResult !== "전체" && filterRecentTestResult !== "All") {
                if (x.recent_test_result === filterRecentTestResult) {
                    return x;
                }
            }
        } else if (filterGender !== "전체" && filterGender !== "All") {
            if (filterRecentTestResult === "전체" || filterRecentTestResult === "All") {
                if ( x.gender === filterGender && x !== null) {
                    return x;
                }
            } else if (filterRecentTestResult !== "전체" && filterGender !== "All") {
                if (x.recent_test_result === filterRecentTestResult && x.gender === filterGender && x !== null) {
                    return x;
                }
            }
        }
    } else if (filterPosition !== "전체" && filterPosition !== "All") {
        if (filterGender === "전체" || filterGender === "All") {
            if (filterRecentTestResult === "전체" || filterRecentTestResult === "All") {
                if (x.position === filterPosition) {
                    return x;
                }
            } else if (filterRecentTestResult !== "전체" && filterRecentTestResult !== "All" ){
                if (x.position === filterPosition && x.recent_test_result === filterRecentTestResult) {
                    return x;
                }
            }
        } else if (filterGender !== "전체" && filterGender !== "All") {
            if (filterRecentTestResult === "전체" || filterRecentTestResult === "All") {
                if (x.position === filterPosition && x.gender === filterGender && x !== null) {
                    return x;
                }
            } else if (filterRecentTestResult !== "전체" && filterRecentTestResult !== "All") {
                if (x.position === filterPosition && x.recent_test_result === filterRecentTestResult && x.gender === filterGender && x !== null) {
                    return x;
                }
            }
        }
    }
});

export default function OneDrop02Employee ({defultlang, TEST_RESULT_TYPE}) {
    let { login_info, lang, textOflang}  = useContext(SiteContext);
    let initial_params = {};

    const [analysislang,setanalysislang] = useState(defultlang);
    useEffect(() => {
        defultlang ="ko-KR";

    },[]);


    let textlang = textOflang[defultlang];
    let textLangSiteMember = textlang.site.employee;
    let textLangtable = textlang.site.employee.table;

    //console.log(' OneDrop02Employee site_key' ,login_info.site_key );
    if(login_info.site_key){
        if(login_info.site_key == 'onedrop'){
            initial_params = {
                "site_key": login_info.site_key,
                "department_code": undefined,
                "entries":200,
                "user_id_contains" : '1drop_'
            };
        }else{
            initial_params = {
                "site_key": login_info.site_key,
                "department_code": undefined,
                "entries":200,
                "user_id_contains": login_info.site_key + '_',

            };
        }
    }else{
        initial_params = {
            "site_key": undefined,
            "department_code": undefined,
            "entries":200
        };
    }

    let [is_loading, field_staff] = useFieldStaff(initial_params);
    const department_to_select = [ ...new Set(testResultByEmployee(field_staff.result).map(row => row.department))];
    const [searchOption, setSearchOption] = useState({
        department : '', //"2020/01/01",
        person : '', //"2022/12/31",
    });
    const position_to_select = [...new Set(testResultByEmployee(field_staff.result).map(row => row.position))];
    const [filterPosition ,setfilterPosition ] = useState(-1);
    const [filterGender ,setfilterGender ] = useState(-1);
    const [filterRecentTestResult ,setfilterRecentTestResult ] = useState(-1);
    const [test_result_by_employee,settest_result_by_employee] = useState();

    const setfilterPositionFun = (param) => setfilterPosition(param);
    const setfilterGenderFun = (param) => setfilterGender(param);
    const setfilterRecentTestResultFun = (param) => setfilterRecentTestResult(param);

    const onSetSearchOption = (vv) => {

        setSearchOption({

            department: (vv.department == "All" || vv.department == "전체") ? '' : vv.department,
            person: (vv.person == "All" || vv.person == "전체") ? '' : vv.person

        } );
    };

    function isEmptyObj(obj)  {
        if(typeof obj === Object){
            if(obj.constructor === Object
                && Object.keys(obj).length === 0)  {
                return true;
            }
        }

        return false;
    }

    useEffect(() => {
        if(isEmptyObj(field_staff) != true){
            settest_result_by_employee( test_result_mapFiltered (filterByDepartmentAndPerson(testResultByEmployee(field_staff.result), searchOption.department,searchOption.person),filterPosition, filterGender,filterRecentTestResult));
        }; return () => null;},[ field_staff,filterPosition,filterGender,filterRecentTestResult,searchOption]);

    return (
            <>
                <div style={{paddingBottom:20}}>
                    <InputFormSearchDist02 handleConfirm={ onSetSearchOption}  UserParam={2} department_to_select = {department_to_select} textLangSitesearhinput={textLangSiteMember.serchinputbox}/>
                </div>
                <div style={{width:'1040px'}}>
                    {(!is_loading) ?
                        <TableEmployeeOnedrop  employee_data={test_result_by_employee} setfilterPositionFun={setfilterPositionFun} setfilterGenderFun={setfilterGenderFun} filterPosition={filterPosition} filterRecentTestResult={filterRecentTestResult} filterGender={filterGender}
                                        setfilterRecentTestResultFun={setfilterRecentTestResultFun} position_to_select={position_to_select} settest_result_by_employee={settest_result_by_employee} textLangSitetable={textLangSiteMember.table} TEST_RESULT_TYPE={TEST_RESULT_TYPE} textLangtablefilterbox={textLangSiteMember.tablefilterbox} textLangtable={textLangtable}/> : ''}
                </div>
            </>
        )
}