//import React from 'react';
import axios from 'axios'



export function requestPost(url,  params, headers, setValue, onCatchError=()=>{}) {
    //const url = props.url;
    //const wh_key = props.wh_key;

    //axios.defaults.withCredentials = true;
    if (!onCatchError) {
        const onCatchError = () => {};
    }

    axios(
        {
            method: 'post',            
            url : url,
            data : params    ,
            headers: headers,
        }
        ).then(response =>{   
            setValue(response.data);

        }
        ).catch(err =>{
            onCatchError(err);

    });
};

export function requestPostWithCredentials(url,  params, headers, setValue, onCatchError=()=>{}) {
    //const url = props.url;  
    //const wh_key = props.wh_key;

    //axios.defaults.withCredentials = true;
    if (!onCatchError) {
        const onCatchError = () => {};
    }

    axios(
        {
            method: 'post',            
            url : url,
            data : params    ,
            headers: headers,
            withCredentials: true,
        }
        ).then(response =>{               
            setValue(response.data);

        }
        ).catch(err =>{
            onCatchError('requestPostWithCredentials:\n', err);

    });
};export function requestPostWithCredentialsDelete(url,  params, headers, setValue, onCatchError=()=>{}) {
    //const url = props.url;
    //const wh_key = props.wh_key;

    //axios.defaults.withCredentials = true;
    if (!onCatchError) {
        const onCatchError = () => {};
    }

    axios(
        {
            method: 'post',
            url : url,
            data : params    ,
            headers: headers,
            withCredentials: true,
        }
        ).then(response =>{
            setValue(response.data);

        }
        ).catch(err =>{
            onCatchError('requestPostWithCredentials:\n', err);

    });
};

export function requestPostIgnoreSSL(url, params,  headers, setValue, onCatchError=()=>{}) {

    if (!onCatchError) {
        const onCatchError = () => {};
    }


    fetch(url, {
        method: 'post',
        //credentials: 'include',
        headers: headers,
        body: JSON.stringify(params),
        }) 
        .then(res => res.json())   
        .then(response =>{   
        setValue(response.data);
        })
        .catch(err =>{
        onCatchError(err);

        });
};

