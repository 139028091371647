
import * as React from 'react';
import {Paper, Container, Typography, List, ListItem, Stack, Box} from '@mui/material';

export default function BoxTestCoverage ({title, title_color, text}) {
    return (
        <>
        <Box
          style={{
              width : '201px',
              height : '139px',
              border : 'solid 1px  #ABABAB',
              boxSizing: 'border-box'
          }}
        >
            <div
            style = {{
                width : 'auto',
                height : '24px',
                margin : '5px 5px 0px 5px'
            }}
            >
                <Typography style={{
                    xdisplay : 'flex',
                    paddingLeft: 0,
                    opacity: 1,
                    fontSize:'15px',
                    fontWeight:'normal',
                    color : '#111', //'RGB(154, 138, 232)',
                    backgroundColor :  title_color,
                }}> {title}</Typography>
            <div
            style = {{
                width: '212px',
                padding : '5px 0 0 0',
                margin : '0 0 0 -6px',

            }}
            >
                <Typography variant='h4'>{text.text1}</Typography>
                <div>{text.text2}</div>
                <Typography>{text.text3}</Typography>
            </div>

            </div>
        

        </Box>

        </>
    );

};