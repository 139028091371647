import {useEffect, useLayoutEffect, useState} from 'react';
import {Box, TextField, TextFieldwithBG, Button, Grid, FormControl, InputLabel, Select, Menu, MenuItem } from "__ui_components/UIComponents";

import SelectItem from "_components_public/SelectItem";
import {useGetBoardList, useFieldTestCenter} from "../__api/requestOneDropData";
import axios from "axios";

export default function ComponentArticleDelete() {
    //let BoardList = null;
    //const [is_loading, BoardList] = useGetBoardDelete();
    //const [BoardList,setBoardList] = useState({});
   // let BoardList = null;

    //BoardList = await getBoardList();

    //useEffect(BoardList,[]);
    // function isEmptyObj(obj)  {
    //     if(typeof obj === Object){
    //         if(obj.constructor === Object
    //             && Object.keys(obj).length === 0)  {
    //             return true;
    //         }
    //     }
    //     return false;
    // }
    //
    //
    //
    // useEffect(() => {
    //     if(isEmptyObj(BoardList) != true){
    //
    //     }; return () => null;},[ BoardList]);
    //
    //
    //    let boardlist = BoardList.articlelist;
    // if (boardlist != null && boardlist.length > 0) {

        return (

            <>

                    <table className="tg">
                        <thead>
                        <tr>
                            <th className="tg-0pky">번호</th>
                            <th className="tg-0pky"></th>
                            <th className="tg-0pky">제목</th>
                            <th className="tg-0pky"></th>
                            <th className="tg-0pky">날짜</th>
                            <th className="tg-0pky"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="tg-0pky">데이터</td>
                            <td className="tg-0pky" colSpan="5"></td>
                        </tr>
                            )
                        }
                        </tbody>
                    </table>
             </>

    )


}