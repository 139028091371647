
import {Box, TextField, TextFieldwithBG, Button, Grid, FormControl, InputLabel, Select, Menu, MenuItem } from "__ui_components/UIComponents";

export default function SelectItemByLabel ({input_label, value, item_to_select, handleChange, is_set_all=true, textLangAnalysissearch}) {

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
          xwidth: 250,
          backgroundColor : '#fff',
        },
      },
    };
    //console.log('textLangAnalysissearch###.boxLabel1',textLangAnalysissearch.boxLabel1);

    return (
        <FormControl fullWidth size="small">
        <InputLabel id="select-item">{input_label}</InputLabel>
        <Select
            labelId="select-item"
            id="select-item"
            //xxdefaultValue={value}
            value = {value == "" ? textLangAnalysissearch.boxLabel1 : value}
            label={input_label}
            onChange={(e) => handleChange(e.target.value == textLangAnalysissearch.boxLabel1 ? "" : e.target.value)}
            sx = {{backgroundColor: '#fff'}}
            MenuProps={MenuProps}                       
            >
                { (is_set_all) ? <MenuItem key={''} value={textLangAnalysissearch.boxLabel1}>{textLangAnalysissearch.boxLabel1}</MenuItem> : ''}
                {
                    item_to_select.map( 
                        (item, index) => <MenuItem key={index} value={item.idx ? item.idx : item.code}>{item.label}</MenuItem>
                        )
                }
          
        </Select>
        </FormControl>
    );
};