


import { textSliced } from '__lib/aTools';
import { subtotalOfAllTestResultType } from "__lib/cTools";

import { useContext, SiteContext } from '__context_api/SiteContext';

import { Typography , Stack, Grid} from '@mui/material'
import CardBasic  from  '_components_public/CardBasic';
import TextBlock3lLine from "../_components_public/TextBlock3lLine";


const InfoBoxGroup = ({field_analysis, test_result_by_department}) => {
    return (
        <>        
        {
            test_result_by_department.map( (dep,index) => {
                return (
                    <Grid item key={index}>
                        <TextBlock3lLine title={dep.label} title_color={dep.title_color} text = {{text1 : dep.text1, text2: dep.text2, text3:dep.text3}} />    
                    </Grid>
                );
            })
        }
        </>      
    );
};


const testResultByDepartment = (list_by_department, number_of_period, departments) => (
    list_by_department.map( 
        dep => (
            {
                idx : dep.idx,
                label : textSliced(dep.name, 23), 
                text1 : `${(dep.rate*100).toFixed(1)}%`,
                text2 : `테스트완료인원 : ${subtotalOfAllTestResultType(dep)}명`,
                text3 : `총 인원 : ${dep.staff_count*number_of_period}명 (${dep.staff_count}명)`,
                title_color :  departments[dep.idx] && departments[dep.idx].color,
            }
        ))
);


export default function CardUser06_TestCoverage({field_analysis, list_by_period, list_by_department, sumOFFieldAnalysis, textLangSiteAnalysisCard}) {

    const { departments }  = useContext(SiteContext); 

    const number_of_period = (list_by_period || []).length;
    const test_result_by_department = testResultByDepartment(list_by_department, number_of_period, departments);    

    const CardBody =
        (sumOFFieldAnalysis != 0) ? (
        <div style={{display:'grid', width:'100%', height:'auto', boxSizing: 'border-box', borderCollapse:'collapse',}}>    
            <Grid container spacing={0} direction='row' style={{ borderCollapse:'collapse',}} >
                <InfoBoxGroup test_result_by_department={test_result_by_department} />
            </Grid>      
        </div>
    ) :
    (<div style={{padding:'0 20px 0 20px'}}>
        <div style={{width: "1008px", height: "240px", "vertical-align": "middle", display: "table-cell", "text-align": "center"}}>{textLangSiteAnalysisCard.error1}</div>
    </div>);

    return (
        <>
            <CardBasic card_title={textLangSiteAnalysisCard.title} card_body={CardBody} style={{width:1040, height:'auto'}}/>
        </>
    )
}