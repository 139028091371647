import {createContext, useEffect, useLayoutEffect, useMemo, useState} from 'react';
import {
    Box,
    TextField,
    TextFieldwithBG,
    Button,
    Grid,
    FormControl,
    InputLabel,
    Select,
    Menu,
    MenuItem,
    TypographyA
} from "__ui_components/UIComponents";

import SelectItem from "_components_public/SelectItem";
import {useGetBoardList, useFieldTestCenter} from "../__api/requestOneDropData";
import axios from "axios";
import OnedropCSArticleBoardDetail from "./onedropCSArticleBoardDetail"
import Onedrop03TestCenter from "./oneDrop03TestCenter";
import {MapIcon, PersonOutlineIcon} from "../__icons/IconProvider";
import Onedrop02Employee from "./oneDrop02Employee";

import ComponentArticle from 'containers/onedropCSArticleBoard';
import ComponentArticleDelete from 'containers/onedropCSArticleBoardDelete';
import ComponentArticleDetail from "../containers/onedropCSArticleBoardDetail";
import ComponentArticleModify from "../containers/onedropCSArticleBoardModify";
import ComponentArticleWrite from "../containers/onedropCSArticleBoardWrite";

import  {MENU_ITEMS} from "../main/SideNavigation"
import articlewrite from "./articlewrite.css"




const ARTICLE_ITEMS = {
    'OnedropCSArticleBoard': {
        jsx: <ComponentArticle/>,
        label: 'CS게시판리스트',
        icon: PersonOutlineIcon,
    },
    'OnedropCsArticleBoardDetail': {
        jsx: <ComponentArticleDetail/>,
        label: 'CS게시판상세',
        icon: PersonOutlineIcon,
    },

    'OnedropCSArticleBoardDelete': {
        jsx: <ComponentArticleDelete/>,
        label: 'CS게시판삭제',
        icon: PersonOutlineIcon,
    },
    'OnedropCSArticleBoardModify': {
        jsx: <ComponentArticleModify/>,
        label: 'CS게시판수정',
        icon: PersonOutlineIcon,
    },
    'OnedropCSArticleBoardWrite': {
        jsx: <ComponentArticleWrite/>,
        label: 'CS게시판작성',
        icon: PersonOutlineIcon,
    }
}



export const ArticleContext = createContext({
    articletypeSelected: () => {},
    setarticletypeSelected: () => {},
    articleSeqSelected: () => {},
    setarticleSeqSelected: () => {},

});










export default function ComponentArticleMain() {
    const ARTICLE_LIST_BY_USER_TYPE =['OnedropCSArticleBoard','OnedropCSArticleBoardDelete','OnedropCSArticleBoardDetail','OnedropCSArticleBoardModify','OnedropCSArticleBoardWrite'];
    const [articletypeSelected, setarticletypeSelected] = useState(ARTICLE_LIST_BY_USER_TYPE[0]);
    const [articleSeqSelected, setarticleSeqSelected] = useState();

    const value = useMemo(() => ({ articletypeSelected, setarticletypeSelected,articleSeqSelected, setarticleSeqSelected }), [articletypeSelected, setarticletypeSelected,articleSeqSelected, setarticleSeqSelected]);


        return (
            <ArticleContext.Provider value={value}>
            <>
                    {ARTICLE_ITEMS[articletypeSelected].jsx}
            </>
            </ArticleContext.Provider>
        )

}