

import {reqLogIn} from "__api/requestLogIn";

import React, {useEffect, useState} from 'react';

import { useHistory } from 'react-router-dom'; 

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';

//import login_side_image from '__images/login_side.png';
//import login_theme from `__images/login_theme1${Math.floor(Math.random()*3)}`;
import login_theme1 from "__images/login_theme1.png";
import login_theme2 from "__images/login_theme2.png";
import login_theme3 from "__images/login_theme3.png";
import login_theme4 from "__images/login_theme4.png";

//import { createTheme, ThemeProvider } from '@mui/material/styles';

const login_theme = [login_theme1, login_theme2, login_theme3, login_theme4][Math.floor(Math.random()*4)];

const langTextForDashboardLogin = {
    "ko-KR": {
        login:{
            text1:"로그인",
            text2:"사용자이름을 입력하세요",
            text3:"비밀번호를 입력하세요",
            text4:"로그인",
            alert1:"로그인이 실패하였습니다.",
            lang:"KOREAN"
        }
    },
    "en-US": {
        login: {
            text1: "Login",
            text2: "Insert UserName",
            text3: "Insert PassWord",
            text4: "Login",
            alert1:"Login Failed",
            lang:"ENGLISH"
        }
    }

}

function storeLoginInfo (login_info) {
  window.localStorage.setItem("onedrop", JSON.stringify(login_info));
}


export default function SignIn() {

    const [loginlang,setloginlang] = useState("en-US");

    const changeLogintextFun = () => {
        if(loginlang == "en-US"){
            setloginlang("ko-KR");
        }else{
            setloginlang("en-US");
        }
    }

  let history = useHistory();
  const login_info = JSON.parse(window.localStorage.getItem("onedrop") || '{}');   
  //console.log('login_info',login_info);
  if (login_info.login === 'success' && login_info.user_type === 'site' ) {
    history.push('/site');        
  };
  if (login_info.login === 'success' && login_info.user_type === 'distributor' ) {
        history.push('/distributor');
  };



  const langTextLogin = langTextForDashboardLogin;
  let loginText = langTextLogin[loginlang];
  loginText = loginText["login"];

  const [loginInfo, setLoginInfo] = React.useState({});
  const [loginResultMsg, setLoginResultMsg] = React.useState('');
  React.useEffect( () => {
    // 로그인.이면 로컬스토리지에 상태저장하고 history.push
    // 로그인실패.면 실패 메시지 표시.


    if (loginInfo.login==='success' && loginInfo.site_key) {
          storeLoginInfo(loginInfo);
          history.push('/site');
      return;
    };

    if (loginInfo.login==='success' && loginInfo.distributor_code) {
            storeLoginInfo(loginInfo);
            history.push('/distributor');
        return;
    };

  }, [loginInfo]);


  const onLogIn = (username, password) => {
    reqLogIn(username, password).then(res => {


      if (res && res.site_key) {
        setLoginInfo(
          {
            login: 'success',
            username: username,
            user_type : 'site',
            site_key : res.site_key,
            name : res.name,
            email : res.email,
            homepage : res.homepage,
            timestamp : Date.now(),
          }
        );        
        return;
      };
        if (res && res.distributor_code) {
            setLoginInfo(
                {
                    login: 'success',
                    username: username,
                    user_type : 'distributor',
                    distributor_code : res.distributor_code,
                    name : res.name,
                    email : res.email,
                    homepage : res.homepage,
                    timestamp : Date.now(),
                }
            );
            return;
        };
      setLoginInfo(
        {
          login: 'fail',
        }
      );
      setLoginResultMsg(loginText.alert1);
      return;
    });
  };

  const handleSubmit = (event) => {
    setLoginResultMsg('');
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    const username = data.get('username');
    const password = data.get('password');




    onLogIn(username, password);
  };



  return (
    <>
    {/* <ThemeProvider theme={theme}> */}

        <Grid container component="main" sx={{ height: '700px'}}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${login_theme})`  , 
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h2" variant="h6">
                {loginText.text1}
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label={loginText.text2}
                name="username"
                xautocomplete="username"
                xautofocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={loginText.text3}
                type="password"
                id="password"
                autoComplete="current-password"
                sx = {{marginTop:1}}
              />
              {/*
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, marginTop:2 }}
              >
                  {loginText.text4}
              </Button>


              <Grid container style={{display: "block"}}>

                <div id={"loginalertdiv"} style={{width:'80%' , float: 'left','text-align': 'center','padding-left': '22%'}}>
                  {loginResultMsg}
                </div>

                  <Button ariant="outlined" size='small'  style={{float:"right",width:'20%'}} onClick={changeLogintextFun}>{loginText.lang}</Button>


              </Grid>



            </Box>
          </Box>
        </Grid>
      </Grid>

    {/* </ThemeProvider> */}
    </>
  );
};