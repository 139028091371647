
import {randomBetween, sum} from '__lib/aTools';
import { TEST_RESULT_TYPE } from '__config/dashboardConfig';

export function randomTestResult (_nn) {
    const nn = _nn || 100;
    const t_nega = randomBetween(Math.round(nn*0.7),nn);
    const t_posi = randomBetween(0, nn-t_nega);
    const t_uncertain = randomBetween(0, nn-t_nega-t_posi);
    const t_cancel = randomBetween(0, nn-t_nega-t_posi-t_uncertain);
    const t_invalid = nn-t_nega-t_posi-t_uncertain-t_cancel;
    return {       
        't-negative'  : {idx : 't-negative' , label : '음성', cnt : t_nega, total : nn, prct: t_nega/nn, },
        't-positive' : {idx : 't-positive' , label : 'Positive',  cnt : t_posi, total : nn, prct: t_posi/nn, },
        't-uncertain' : {idx : 't-uncertain' ,label : '불확정',  cnt : t_uncertain, total : nn, prct: t_uncertain/nn, },
        't-invalid' : {idx : 't-invalid' ,label : '무효', cnt : t_invalid, total : nn, prct: t_invalid/nn, },
        't-cancel' : {idx : 't-cancel' ,label : '취소', cnt : t_cancel, total : nn, prct: t_cancel/nn, },
    };
};

export function subtotalOfAllTestResultType ( test_by_one_segment)  {
    return sum( Object.keys(TEST_RESULT_TYPE).map( idx => test_by_one_segment[idx == "inconclusive" ? "indeterminate"  :  idx]));
};

export function totalTestCountFromObject (result,cardmap) {
    //const _list = listOfTestResult (result);

    console.log('result',result);
    if (!result || typeof result !== 'object') {
        return null;
    };
    if(cardmap =="cardmap"){
        return sum( Object.keys(TEST_RESULT_TYPE).map( idx => result[idx == "inconclusive" ? "indeterminate"  :  idx]));
    }else{
        return sum( Object.keys(TEST_RESULT_TYPE).map( idx => result[idx == "inconclusive" ? "indeterminate"  :  idx]));
    }
}

export function listOfTestResult (result) {
    if (!result || typeof result !== 'object') {
        return [];
    }
    return [
        {idx: 'negative', cnt : result.negative,},   
        {idx: 'positive', cnt : result.positive, },   
        {idx: 'inconclusive', cnt : result.indeterminate, },
        {idx: 'invalid', cnt : result.invalid, },   
        {idx: 'cancel', cnt : result.cancel, },   
    ];  
};

/*
const data_smaple = [
    {idx : 'test-negative' , label : '음성', content: '93% | 637', icon : <FaceIcon style={{color:'#666'}}/>,},
    {idx : 'test-positive' , label : '양성', content: '2% | 19', icon :  <FaceIcon style={{color:'#666'}}/>,},
    {idx : 'test-uncertain' ,label : '불확정', content: '3% | 29', icon :  <FaceIcon style={{color:'#666'}}/>,},
    {idx : 'test-invalid' ,label : '무효', content: '1% | 11', icon :  <FaceIcon style={{color:'#666'}}/>,},
    {idx : 'test-cancel' ,label : '취소', content: '1% | 11', icon :  <FaceIcon style={{color:'#666'}}/>,},
];

const departments = [
    {idx : 'prod', label : '생산', color : ''},
    {idx : 'design', label : '디자인', color : ''},
    {idx : 'cs', label : 'CS', color : ''},
    {idx : 'sales', label : '영업', color : ''},
    {idx : 'marketing', label : '마케팅', color : ''},
    {idx : 'admin', label : '경영지원', color : ''},
]; 

const data = Object.fromEntries(departments.map( row => [row.idx, {...row, rows : data_smaple,} ]));
*/