
import axios from 'axios';



export const requestDeleteTableRowForTestCenter = async(row) => {  //선택된 테이블 Row 삭제
    const headers = {
        'Content-Type': 'application/json',
    };

    let result = null;
    try {
        result =  await axios.delete('https://jsonplaceholder.typicode.com/posts/1',//임시 테스트 주소
           // axios.delete('https://jsonplaceholder.typicode.com/possdts/1',//임시 테스트 주소
            { headers,
            data: {
                date: row.date,
                test_time: row.test_time,
                staff_code: row.staff_code
            },
            withCredentials: true,
        })/*.then(function (response) {
            // handle success

        })*/
    }catch(error){
        console.error(error);

    }

    return result;
}
export const requestDeleteTableRowForMemberOfClinomic = async(row) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    let result = null;
        try{
        result =  await axios.delete('https://jsonplaceholder.typicode.com/posts/1',//임시 테스트 주소
        // axios.delete('https://jsonplaceholder.typicode.com/possdts/1',//임시 테스트 주소
        { headers,
            data: {
                name: row.name,
                employee_code: row.employee_code
            },
            withCredentials: true,
        })



        /*.then(function (response) {
        // handle success

        return response;*/

    }catch(error) {
        // handle error


    }
    return result;
}






export const requestSendSmsFuc = async (row,table_title) =>{


    //QUMxYTBlODZjODdkZjE2ZGY2MDhmODlhNjBjNzU3Yjc0Njo4MTc2MmNhM2U4ZDI0ZWM2NjVmMjllNWNmZWY0MWE0NA==
    const headers = {"headers" :{'Content-Type':'application/x-www-form-urlencoded',
            "Authorization": "Basic QUMxYTBlODZjODdkZjE2ZGY2MDhmODlhNjBjNzU3Yjc0Njo4MTc2MmNhM2U4ZDI0ZWM2NjVmMjllNWNmZWY0MWE0NA=="
        }
    };
    //console.log("phone",row.phone);
    if(row.phone == undefined  || row.phone == null){
        if(table_title == "Employee Inspection Status"){
            alert("There is no resistered Phone Number.");
        }else{
            alert("등록된 phone Number가 없습니다.");
        }
        return false;
    }
    let params = new URLSearchParams();
    params.append('To', '+82' + String(row.phone)); //한국


    //params.append('To', row.phonenum);//추후 개발하면 주석해제 후 수정
    if(table_title == "Employee Inspection Status"){
        //en
        params.append('Body', "ID: " + row.name + "\n"+"Staff Code: "+row.employee_code+"\n"+ "Inspection Result: " + row.recent_test_result);
    }else{
        //ko
        params.append('Body', "ID: " + row.name + "\n"+"사번: "+row.employee_code+"\n"+ "검사결과: " + row.recent_test_result);
    }

    params.append('MessagingServiceSid', 'MG0fd4cc7645efb90bc1982a62022476c3');

    let result = null;
    let url = "https://api.twilio.com/2010-04-01/Accounts/AC1a0e86c87df16df608f89a60c757b746/Messages.json";
    //url = process.env.REACT_APP_SENDESMS||"/2010-04-01/Accounts/AC1a0e86c87df16df608f89a60c757b746/Messages.json";
        try {

            result = await axios.post(
                // "https://api.twilio.com/2010-04-01/Accounts/AC1a0e86c87df16df608f89a60c757b746/Messages.json",
                url,
                // "To:'+8201097851715', Body:'This is test sms2', MessagingServiceSid:MG0fd4cc7645efb90bc1982a62022476c3"
                // "To:'+8201046517359', Body:'This is test sms', MessagingServiceSid:MG0fd4cc7645efb90bc1982a62022476c3"
                params,
                headers,

            ) .then(function (response) {
                // handle success
                if(table_title == "Employee Inspection Status"){
                    alert('SMS was sended to ' +  row.name );
                }else{
                    alert('SMS가' +  row.name + '님께 \n발송되었습니다.');
                }



            }).catch(function(error){


            })


        }catch(error){

        };

};

export const sendEmailFun = async(row,table_title) => {


    //QUMxYTBlODZjODdkZjE2ZGY2MDhmODlhNjBjNzU3Yjc0Njo4MTc2MmNhM2U4ZDI0ZWM2NjVmMjllNWNmZWY0MWE0NA==
   /* const headers = {"Authorization": /!*Bearer <<YOUR_API_KEY>>*!/",'Content-Type:' application/json'};*/


    //let params = new URLSearchParams();
    /*params.append('To', 'xenexes@naver.com');

*/

    if(row.email == undefined || row.email == "" || row.email == null){

        if(table_title == "Employee Inspection Status"){
            alert("There is no resistered email.");
        }else{
            alert("등록된 email이 없습니다.");
        }
        return false;
    }
    let params;
    if(table_title == "Employee Inspection Status") {
        params = {name: row.name, email: row.email, recent_test_result: row.recent_test_result,table_title:table_title};
    }else{
        params = {name: row.name, email: row.email, recent_test_result: row.recent_test_result,table_title:table_title};

    }


    let result = null;
    const headers =  {
               // Authorization:"Bearer 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",
                "Content-Type":"application/json"
    }

    let URL = "http://localhost:4000/getmailparamtosend";
    //URL = process.env.REACT_APP_SENDMAIL

    /*if(process.env.development != "production"){
        URL = '/v3/mail/send';
    }
    else{
        URL = process.env.REACT_APP_DISTRIBUTOR_ANALYSIS;
    }*/
    //const URL = process.env.REACT_APP_SENDEMAILSEND;
    try {

        let res = await axios(
        {
            method: 'get',
            url : URL,
            data : params    ,
            headers: headers,
            withCredentials : false,
        }
        );
      // axios.defaults.withCredentials = true;


        if(res.status === 202){
            // test for status you want, etc

            if(table_title == "Employee Inspection Status"){
                alert('Email was sended to ' +  row.name );
            }else{
                alert('이메일이 ' +  row.name + '님께 \n발송되었습니다.');
            }

        }
        // Don't forget to return something
        return res.data
    }


    /*
    try {



        result = await axios.post(
            "/v3/mail/send",data,{headers:
                    {
                        Authorization:"Bearer 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",
                    "Content-Type":"application/json"}
            }
            // "To:'+8201097851715', Body:'This is test sms2', MessagingServiceSid:MG0fd4cc7645efb90bc1982a62022476c3"
            // "To:'+8201046517359', Body:'This is test sms', MessagingServiceSid:MG0fd4cc7645efb90bc1982a62022476c3"


        ) .then(function (response) {
        // handle success

        })



    }*/

    catch(error){

    };
}
