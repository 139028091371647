import {useContext, useEffect, useState} from 'react';

import { 
    useLocationVender
    } from "__api/requestOneDropDistributorData";


//import {Button, Stack} from '@mui/material';

import {Box, TextField, TextFieldwithBG, Button, Grid, FormControl, InputLabel, Select,  MenuItem } from "__ui_components/UIComponents";

import TableDistributorInfo from '_components_table/TableDistributorInfo';

import { debugMsg } from "__lib/debugMsg";
import {TEST_RESULT_TYPE} from "../__config/dashboardConfig";
import SelectItem from "../_components_public/SelectItem";
import OneDropDis01Analysis from "./oneDropDis01Analysis";
import {DistributorContext} from "../__context_api/DistributorContext";
const IS_LOCAL_DEBUG = false;
const DebugMsg = debugMsg(IS_LOCAL_DEBUG);



const testResultBySeller = (result) => {
    if (!result || Object.keys(result).length===0) {
        return [];
    };
    const test_result_map = Object.keys(TEST_RESULT_TYPE);
    return result.map(
        row => (
            {
                name : row.name || '',

            }
        ));
};

function InputFormSearchDist02({ handleConfirm,seller_to_select,textLangdistributorrVendors}) {

    const [vendor, setVendor] = useState('');
    const [contact, setContact] = useState('');
    const onClickConfirm = () => {
        handleConfirm({
            vendor : (vendor == "전체" || vendor == "All") ? "" : vendor ,
            contact : contact,
        });
    };

    return (
        <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '200px'  },
          display : 'flex',
        }}
        noValidate
        autoComplete="off">
            <SelectItem input_label={textLangdistributorrVendors.box1} value={vendor} item_to_select={seller_to_select}  handleChange={setVendor} is_set_all={true} textLangSitesearhinput={textLangdistributorrVendors}/>
            <TextFieldwithBG size="small" id="outlined-basic" label={textLangdistributorrVendors.box2} variant="outlined" onKeyup={()=>onClickConfirm()} onChange={(e)=> setContact(e.target.value)}   />
        <Button sx={{maxWidth:50}} variant='contained' onClick={()=>onClickConfirm()}>{textLangdistributorrVendors.submit}</Button>
      </Box>

    ); 
};


export default function OneDropDis02Seller ({defultlang}) {
    const {login_info,lang, textOflang, setdefultlang} = useContext(DistributorContext);
    //console.log('login_info in  DistributorContext' ,login_info.distributor_code );
    let initial_params = {};

    const [analysislang,setanalysislang] = useState(defultlang);
    let textlang = textOflang[defultlang];
    let textLangdistributorrVendors = textlang.distributor.vendors;
    let textLangtable = textlang.distributor.vendors;
    //console.log('textLangdistributorrVendors',textLangdistributorrVendors);
    useEffect(() => {
        return () =>  null;

    },[])
    if(login_info.distributor_code){
        if(login_info.distributor_code == 'onedrop'){
            initial_params = {
                "distributor_code": login_info.distributor_code,
                "page" : 0,
                "entries" : 200,
                "user_id_contains": '1drop_' ,
            };
        }else if(login_info.distributor_code == 'mediconhellas'){
            initial_params = {
                "distributor_code": login_info.distributor_code,
                "page" : 0,
                "entries" : 200,
                "user_id_contains": 'Hellas_'
            }
        }else if(login_info.distributor_code == 'biowings'){
            initial_params = {
                "distributor_code": login_info.distributor_code,
                "page" : 0,
                "entries" : 200,
                "user_id_contains": 'Biowings_'
            }
        }else{
            initial_params = {
                "distributor_code":  login_info.distributor_code,
                "page" : 0,
                "entries" : 200,
                "user_id_contains": login_info.distributor_code + '_'
            };
        }
    }else{
        initial_params = {
            "distributor_code":  undefined,
            "page" : 0,
            "entries" : 100
        };
    }
    //console.log('initial_params',initial_params);
    const [is_loading, location_vendor ] = useLocationVender(initial_params);

    const seller_to_select = [ ...new Set(testResultBySeller(location_vendor.vendor).map(row => row.name))];
    const [searchOption, setSearchOption] = useState({
        vendor : '', //"2020/01/01",
        contact : '', //"2022/12/31",
    });

    const onSetSearchOption = (vv) => {

        setSearchOption(vv);
    };

    return (
        <>

        <div style={{paddingBottom:20}}>
            <InputFormSearchDist02 handleConfirm={onSetSearchOption} seller_to_select={seller_to_select} textLangdistributorrVendors={textLangdistributorrVendors.serchinputbox}/>
        </div>
        <div style={{maxWidth:'800px', padding:"0 0 10px 0"}} >
            <DebugMsg data = {location_vendor} label='Distributor02Seller:location_vendor=' />
        </div>
        <div style={{width:'1040px'}}>
            <TableDistributorInfo location_vendor={location_vendor} as_vendor={searchOption.vendor} as_contact={searchOption.contact} textLangdistributorrVendors={textLangdistributorrVendors.table} textLangtable={textLangtable}/>
        </div>
        </>
    )
}