
import './App.css';

import React from 'react';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import Entry from "main/Entry";


function App() {

  return (
    <BrowserRouter>
      <div className="App" style={{minHeight: '100vh', width:'100vw', backgroundColor:'#f5f5f5'}}>
      <Entry />
    </div>


    </BrowserRouter>
  );
}

export default App;
